import React from "react";
import { deleteDocument } from "../../../../../apis/deleteDocument";
import "./DeleteDocuments.scss";
import { toast } from "react-toastify";
import { hideDocument } from "../../../../../apis/HideDocument";

export interface TextProps {
  handleCloseHideModal: any;
  docID: any;
  setIsHideLoading: any;
}

const HideDocument: React.FC<TextProps> = ({
  handleCloseHideModal,
  docID,
  setIsHideLoading,
}) => {
  const onHideDocument = () => {
    setIsHideLoading(true)
    const update = {
      id: docID,
      is_hidden: true,
    };
    hideDocument(update)
      .then((resp) => {
        setIsHideLoading(false)
        handleCloseHideModal();
        toast.success("Document Hide Successfully");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error hiding documents. Please try again.");
        handleCloseHideModal();
      });
  };
  return (
    <div className="delete-document-container">
      <h3>Are you sure to Hide this Document.?</h3>
      <div className="delete-buttons">
        <button onClick={onHideDocument}>Yes</button>
        <button onClick={handleCloseHideModal}>Cancel</button>
      </div>
    </div>
  );
};

export default HideDocument;
