import React, { ChangeEvent } from "react";
import "./HeroText.scss";
import { updateTemplate } from "../../../../apis/updateTemplate";
import { toast } from "react-toastify";

export interface TextProps {
  note: string;
  setNote: React.Dispatch<React.SetStateAction<string>>;
  handleCloseTextModal: () => void;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const PopularText: React.FC<TextProps> = ({
  note,
  setNote,
  handleCloseTextModal,
  loading,
  setLoading,
}) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newNote = event.target.value;
    setNote(newNote);
  };

  const onUpdateNote = () => {
    const update = {
      popular_laws_body_en: note,
    };
    setLoading(true);
    updateTemplate(update)
      .then((response) => {
        toast.success("Text Updated Successfully");
        handleCloseTextModal();
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Text Updating Error. Please Try Again.");
      });
  };

  return (
    <div className="edit-text">
      <input
        type="text"
        value={note}
        onChange={handleInputChange}
        className="edit-input"
      />
      <button className="edit-button" onClick={onUpdateNote}>
        {loading ? "Loading..." : "Update"}
      </button>
    </div>
  );
};

export default PopularText;
