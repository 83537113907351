import React, { useCallback, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import "./groupsSetup.scss";
import AQCheckinTable from "../../../../ReuseableComponents/Table/Table";
import { GroupTableColumns } from "./GroupSetup.utils";
import ModalForm from "./GroupForm/GroupFormES";
import { getGroupsList } from "../../../../../apis/groupApis/getGroupsList";
import { deleteGroup } from "../../../../../apis/groupApis/deleteGroup";
import { Modal, Button } from "reactstrap";
import AQModal from "../../../../ReuseableComponents/AQModal/Modal/Modal";
import { toast } from "react-toastify";
import Loader from "../../../../ReuseableComponents/Loader/Loader";
import { GroupTableColumnsES } from "./GroupSetup.utilsES";

const GroupSetupES: React.FC = () => {
  const [modal, setModal] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [userClickData, setUserClickData] = useState({
    id: 0,
    type: "",
  });
  const { data: groupMeta } = useQuery(["getGroupList"], getGroupsList, {
    refetchInterval: 1000,
  });
  const toggle = () => {
    setUserClickData({
      id: 0,
      type: "",
    });
    setModal(!modal);
  };

  const onDelete = useCallback(
    (id: number) => {
      setUserClickData({
        id: id,
        type: "delete",
      });
      setshowModal(!showModal);
    },
    [showModal, setshowModal]
  );
  const onEdit = useCallback(
    (id: number) => {
      setUserClickData({
        id: id,
        type: "edit",
      });
    },
    [showModal, setshowModal]
  );

  const onConfirm = () => {
    if (userClickData?.type === "delete") {
      deleteGroup(userClickData?.id)
        .then((resp) => {
          setshowModal(false);
          toast.success("Se elimina el grupo");
        })
        .catch((err) => {
          setshowModal(false);
          toast.error("Error al eliminar el grupo");
        });
    } else return;
  };

  return (
    <>
      <div>
        {groupMeta?.groups?.length === 0 && (
          <button className="confirm-btn" onClick={toggle}>Crea un Grupo</button>
        )}
        {!groupMeta?.groups && <Loader width={300} />}
        {groupMeta?.groups?.length >= 1 && (
          <div>
          <div className="topbar">
            <h2 className="title-heading">Grupos</h2>
            <button className="add-user-button" onClick={toggle}>+Nueva</button>
          </div>
              <AQCheckinTable
                columns={GroupTableColumnsES(onDelete, onEdit)}
                data={groupMeta?.groups}
              />
          </div>
        )}
          <ModalForm
            modal={modal}
            toggle={toggle}
            setModal={setModal}
            setshowModal={setshowModal}
            userClickData={userClickData}
            setUserClickData={setUserClickData}
          />
      </div>

      <Modal
        isOpen={showModal}
        toggle={() => setshowModal(!showModal)}
        className="user-modal"
      >
        <AQModal
          nextBtnText={
            <>
              <Button className="confirm-btn" onClick={onConfirm}>
              Sí
              </Button>
            </>
          }
          cancelBtnText={
            <>
              <Button
                className="cancel-btn"
                onClick={() => setshowModal(false)}
              >
                Cancelar
              </Button>
            </>
          }
          modal={showModal}
          onConfirm={onConfirm}
          onExitModal={() => setshowModal(!showModal)}
        >
          <span className="user-modal-body">
          Estás seguro que quieres{" "}
            {userClickData?.type === "delete" && "Borrar"} ?
          </span>
        </AQModal>
      </Modal>
    </>
  );
};

export default GroupSetupES;
