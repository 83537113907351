import { useEffect, useState } from "react";
import { getSignUpUserDetail } from "../apis/getSignupUserDetail";
import AdminHeader from "../components/desktop/DE/AdminSetting/AdminHeader/AdminHeader";
import { getInsightDetails } from "../apis/getInsightDetails";

type Props = {};

function AdminSetting(Props: Props) {
  let userID = localStorage.getItem("user_id");
  const [staff, setStaff] = useState();
  const [insightDetails, setInsightDetails] = useState();

  useEffect(() => {
    getSignUpUserDetail(userID)
      .then((userData) => {
        setStaff(userData?.is_staff);
      })
      .catch((err) => {
        console.log(err);
      });
    getInsightDetails()
      .then((insightResponse) => {
        setInsightDetails(insightResponse?.document_insights);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [staff]);
  
  return <AdminHeader staff={staff} insightDetails={insightDetails} />;
}

export default AdminSetting;
