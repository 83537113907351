import axios, { AxiosError } from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
    baseURL: baseURL
}); 


export const uploadDocuments = async (upload: any) => {
  if (!upload) {
    return null;
}
let uploadType;
if (upload.type === 1) {
    uploadType = "Ley";
} else if (upload.type === 2) {
    uploadType = "RC";
}else if (upload.type === 3) {
    uploadType = "Vetos";
  } else if (upload.type === 4) {
    uploadType = "Reglamento de Agencias";
  }else if (upload.type === 5) {
    uploadType = "Reglamento Municipal";
  }else if (upload.type === 6) {
    uploadType = "Ordenanza Municipal";
  }else if (upload.type === 7) {
    uploadType = "Resolución Municipal";
  }else {
    uploadType = "Ley";
  }

  
let uploadDocumentSection;
if (upload.document_section === 1) {
    uploadDocumentSection = "none";
} else if (upload.document_section === 2) {
    uploadDocumentSection = "popular";
}else if (upload.document_section === 3) {
    uploadDocumentSection = "historical";
  }else {
    uploadDocumentSection = "none";
  }

  let token = localStorage.getItem('token');
  try {
    let formData = new FormData();
    formData.append('name', upload.name);
    formData.append('type', uploadType);
    formData.append('law_number', upload.law_number);
    formData.append('year', upload.year);
    formData.append('date', upload.date);
    formData.append('status', upload.status);
    formData.append('file', upload.file);
    formData.append('format', 'pdf');
    formData.append('regulation_number', upload.regulation_number);
    formData.append('agency', upload.agency);
    formData.append('municipality_number', upload.municipality_number);
    formData.append('is_indexed', upload.is_indexed);
    formData.append('is_titled', upload.is_titled);
    formData.append('is_completed', upload.is_completed);
    formData.append('municipality', upload.municipality);

    let result = await api.post('documents/', formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return result.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError: AxiosError = error;
      if (axiosError.response) {
        console.log('Error response:', axiosError.response.data);
        console.log('Error status:', axiosError.response.status);
        console.log('Error headers:', axiosError.response.headers);
      }
    }
    throw error;
  }
};
