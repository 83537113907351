import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import "./DocumentResultCSV.scss";
import { exportCSV } from "../../../../../apis/exportCSV";

type FormInputs = {
  type: string;
  year: string;
};

const DocumentResultCSV: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [searchCSVResult, setSearchCSVResult] = useState<string[][] | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>();

  const types = [
    { label: "Law", value: "Ley" },
    { label: "Joint Resolution", value: "RC" },
    { label: "Agencies Regulations", value: "Reglamento de Agencias" },
    { label: "Municipal Regulation", value: "Reglamento Municipal" },
    { label: "All", value: "all" },
  ];

  const years = [...Array.from({ length: 125 }, (_, i) => (1901 + i).toString()), { label: "All", value: "all" }];

  const staticHeaders = ["Document ID", "Type", "Law Number", "Year", "Title", "Date"];

  const transformCSV = (csv: string): string[][] => {
    const rows = csv.split("\n").map((row) => row.split(","));
    return rows;
  };

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    setLoading(true);
    exportCSV(data)
      .then((resp) => {
        if (resp && typeof resp === "object") {
          // Extract keys (columns) and values (rows)
          const keys = Object.keys(resp); // ["doc_id", "type", "law_number", "title", "date"]
          const maxLength = Math.max(...keys.map((key) => resp[key].length));

          // Transform the object into rows (handling varying array lengths)
          const transformedData = Array.from({ length: maxLength }, (_, rowIndex) =>
            keys.map((key) => resp[key][rowIndex] || "")
          );

          setSearchCSVResult(transformedData);
        } else if (typeof resp === "string") {
          // If resp is a CSV string, split into rows and columns
          const transformedData = transformCSV(resp);
          setSearchCSVResult(transformedData);
        } else if (Array.isArray(resp)) {
          // If resp is already an array, directly set it
          setSearchCSVResult(resp);
        }
        setIsModalOpen(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const downloadCSV = () => {
    if (searchCSVResult) {
      // Add headers to the CSV data (same as the table headers)
      const headers = staticHeaders.join(",") + "\n";
  
      // Map through the rows and format each cell
      const rows = searchCSVResult
        .map((row) => {
          return row
            .map((cell) => {
              if (typeof cell === "string") {
                return `"${cell.replace(/"/g, '""')}"`; // Escape quotes
              }
              return cell;
            })
            .join(","); // Join cells with commas
        })
        .join("\n"); // Join rows with newline characters
  
      // Combine headers and rows into a single CSV content
      const csvContent = headers + rows;
  
      // Create a UTF-8 encoded Blob with BOM to handle special characters
      const bom = "\ufeff"; // BOM character
      const blob = new Blob([bom + csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      
      // Create a link and trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = "document_results.csv";
      link.click();
      URL.revokeObjectURL(url);
    }
  };
  
  

  return (
    <div className="document-result-csv">
      <h2 className="document-heading-csv">Export Document Results into a CSV File</h2>
      <form onSubmit={handleSubmit(onSubmit)} className="csv-form">
        <div className="filters">
          <div className="filter-item">
            <label htmlFor="type-select">Type:</label>
            <select
              id="type-select"
              {...register("type", { required: "Type is required." })}
            >
              <option value="">Select Type</option>
              {types.map((t, index) => (
                <option key={index} value={t.value}>
                  {t.label}
                </option>
              ))}
            </select>
            {errors.type && <p className="error-message">{errors.type.message}</p>}
          </div>
          <div className="filter-item">
            <label htmlFor="year-select">Year:</label>
            <select
              id="year-select"
              {...register("year", { required: "Year is required." })}
            >
              <option value="">Select Year</option>
              {years.map((y, index) => (
                <option key={index} value={typeof y === "string" ? y : y.value}>
                  {typeof y === "string" ? y : y.label}
                </option>
              ))}
            </select>
            {errors.year && <p className="error-message">{errors.year.message}</p>}
          </div>
        </div>
        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? "Exporting..." : "Export to CSV"}
        </button>
      </form>

      {/* Modal */}
      {isModalOpen && searchCSVResult && (
        <div className="modal-overlay" onClick={() => setIsModalOpen(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>CSV Export Result</h2>
            <div className="csv-table-wrapper">
              <table className="csv-table">
                <thead>
                  <tr>
                    {staticHeaders.map((header, index) => (
                      <th key={index}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {searchCSVResult.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <button onClick={downloadCSV} className="download-button">
              Download CSV
            </button>
            <button onClick={() => setIsModalOpen(false)} className="close-button">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentResultCSV;
