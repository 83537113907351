import { ColumnType } from "../../../../ReuseableComponents/Table/Table.typs";

export const UserTableColumnsDeleted: (
  onDelete: (id: number) => void,
  onEdit: (id: number) => void,
  onLock: (id: number) => void
) => ColumnType[] = (onDelete, onEdit, onLock) => [
  {
    name: "First Name",
    dataKey: "first_name",
    align: "left",
    width: "10%",
  },
  {
    name: "Last Name",
    dataKey: "last_name",
    align: "left",
    width: "10%",
  },
  {
    name: "Email",
    dataKey: "email",
    align: "left",
    width: "10%",
  },
  {
    name: "Joined Date",
    dataKey: "date_joined",
    align: "left",
    width: "10%",
    limit: 25,
    render: (cellData, rowData) => {
      return (
        <span>{(rowData?.date_joined?.slice(0, 10))}</span>
      );
    },
  },
];

