import React, { useState } from "react";
import "./SearchDocuments.scss";
import { Input } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import Footer from "../Footer";
import RadioButtonsGroupES from "./RadioButtonsES";
import { uploadDocuments } from "../../../../apis/uploadDocuments";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import UploadHeaderES from "../Header/UploadHeaderES";
import {
  agenciesOptions,
  municipalOptions,
  statusOptions,
  years,
} from "./Data/DataES";
import FormSelector from "../FormSelector/FormSelector";
import RadioButtonsSectionES from "./RadioButtonsSectionES";

type Props = {};
export interface SearchData {
  name: string;
  law_number: any;
  type: any;
  year: any;
  status: any;
  date: string;
  file: string;
  regulation_number: any;
  agency: any;
  municipality_number: any;
  municipality: any;
  document_section: any;
}

const UploadDocumentsES = (props: Props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isIndexed, setIsIndexed] = useState(false);
  const [isTitled, setIsTitled] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const navigate = useNavigate();
  const bottomRef = React.useRef<HTMLDivElement>(null);

  const scrollHandler = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleCancel = () => {
    navigate("/en");
  };
  const [isRadio, setIsRadio] = useState<any>(1);
  const [isRadioSection, setIsRadioSection] = useState<any>(1);
  const [generalFields, setGeneralFields] = useState(true);
  const [agenciesFields, setAgenciesFields] = useState(false);
  const [municipalFields, setMunicipalFields] = useState(false);
  const [lawFields, setLawFields] = useState(true);

  const [selectedStatus, setSelectedStatus] = useState("");
  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatus(event.target.value);
  };

  const [selectedYear, setSelectedYear] = useState("");
  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(event.target.value);
  };

  const [selectedAgency, setSelectedAgency] = useState("");
  const handleAgencyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedAgency(event.target.value);
  };

  const [selectedMunicipal, setSelectedMunicipal] = useState("");
  const handleMunicipalChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedMunicipal(event.target.value);
  };

  const { handleSubmit, control } = useForm<SearchData>({
    defaultValues: {
      name: "",
      law_number: "",
      type: isRadio,
      year: "",
      status: "",
      date: "",
      regulation_number: "",
      agency: "",
      municipality_number: "",
      municipality: "",
      document_section: isRadioSection,
    },
    mode: "onChange",
  });

  const onUploadDocuments = (data: SearchData) => {
    const upload = {
      type: isRadio,
      name: data.name,
      law_number: data.law_number,
      year: selectedYear,
      date: data.date,
      status: selectedStatus,
      file: selectedFile,
      regulation_number: data.regulation_number,
      agency: selectedAgency,
      municipality_number: data.municipality_number,
      municipality: selectedMunicipal,
      document_section: isRadioSection,
      is_indexed: isIndexed,
      is_titled: isTitled,
      is_completed: isCompleted,
    };
    setIsLoading(true);
    uploadDocuments(upload)
      .then((response) => {
        toast.success("Cargar documentos con éxito");
        setIsLoading(false);
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.error("Error al cargar documentos. Inténtalo de nuevo.");
      });
  };

  return (
    <div className="search-document-page">
      <UploadHeaderES />
      <div className="search-documents-container">
        <div className="search-document">
          <div className="form-container">
            <h1 className="page-title">Subir Documentos</h1>
            <div className="radio-buttons">
              <h2 className="radio-buttons-title">Sección de Documentos:</h2>
              <RadioButtonsSectionES
                isRadioSection={isRadioSection}
                setIsRadioSection={setIsRadioSection}
              />
            </div>
            <div className="radio-buttons">
              <h2 className="radio-buttons-title">Tipo de Documentos:</h2>
              <RadioButtonsGroupES
                isRadio={isRadio}
                setIsRadio={setIsRadio}
                setGeneralFields={setGeneralFields}
                setAgenciesFields={setAgenciesFields}
                setMunicipalFields={setMunicipalFields}
                setLawFields={setLawFields}
              />
            </div>
            <form
              className="search-documents-form"
              onSubmit={handleSubmit(onUploadDocuments)}
            >
              <div className="search-bars">
                <h2 className="form-label">Detalle de los Documentos:</h2>
                <div
                  className="filters"
                  style={{ display: generalFields ? "flex" : "none" }}
                >
                  <Controller
                    name="law_number"
                    control={control}
                    defaultValue="law_number"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        value={value}
                        error={error}
                        id="law_number"
                        type="number"
                        name="law_number"
                        placeholder="Número de Ley"
                        onChange={onChange}
                        className="document-input"
                      />
                    )}
                  />
                  <FormSelector
                    value={selectedYear}
                    options={years}
                    onChange={handleYearChange}
                  />
                  <Controller
                    name="date"
                    control={control}
                    defaultValue="date"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        value={value}
                        error={error}
                        id="date"
                        type="text"
                        name="date"
                        placeholder="Fecha"
                        onChange={onChange}
                        className="document-input"
                      />
                    )}
                  />
                </div>
                <div
                  className="filters"
                  style={{ display: agenciesFields ? "flex" : "none" }}
                >
                  <Controller
                    name="regulation_number"
                    control={control}
                    defaultValue="regulation_number"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        value={value}
                        error={error}
                        id="regulation_number"
                        type="number"
                        name="regulation_number"
                        placeholder="Número de Reglamento"
                        onChange={onChange}
                        className="document-input"
                      />
                    )}
                  />
                  <Controller
                    name="date"
                    control={control}
                    defaultValue="date"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        value={value}
                        error={error}
                        id="date"
                        type="text"
                        name="date"
                        placeholder="Fecha"
                        onChange={onChange}
                        className="document-input"
                      />
                    )}
                  />
                </div>
                <div
                  className="filters"
                  style={{ display: agenciesFields ? "flex" : "none" }}
                >
                  <FormSelector
                    value={selectedAgency}
                    options={agenciesOptions}
                    onChange={handleAgencyChange}
                  />
                  <FormSelector
                    value={selectedYear}
                    options={years}
                    onChange={handleYearChange}
                  />
                </div>
                <div
                  className="filters"
                  style={{ display: municipalFields ? "flex" : "none" }}
                >
                  <Controller
                    name="municipality_number"
                    control={control}
                    defaultValue="municipality_number"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        value={value}
                        error={error}
                        id="municipality_number"
                        type="number"
                        name="municipality_number"
                        placeholder="Número de Municipio"
                        onChange={onChange}
                        className="document-input"
                      />
                    )}
                  />
                  <Controller
                    name="date"
                    control={control}
                    defaultValue="date"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        value={value}
                        error={error}
                        id="date"
                        type="text"
                        name="date"
                        placeholder="Fecha"
                        onChange={onChange}
                        className="document-input"
                      />
                    )}
                  />
                </div>
                <div
                  className="filters"
                  style={{ display: municipalFields ? "flex" : "none" }}
                >
                  <FormSelector
                    value={selectedMunicipal}
                    options={municipalOptions}
                    onChange={handleMunicipalChange}
                  />
                  <FormSelector
                    value={selectedYear}
                    options={years}
                    onChange={handleYearChange}
                  />
                </div>
                <div className="search-form-field">
                  <label>Nombre</label>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue="name"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <textarea
                        value={value}
                        onChange={onChange}
                        id="name"
                        name="name"
                        placeholder="Ingrese su Nombre"
                        className="document-input-text-area"
                        required
                      />
                    )}
                  />
                </div>
                <FormSelector
                  value={selectedStatus}
                  options={statusOptions}
                  onChange={handleStatusChange}
                />
                <input
                  type="file"
                  accept=".pdf"
                  required
                  onChange={handleFileChange}
                  className="document-input"
                />
              </div>
              <div className="checkboxes">
                <div className="checkbox-field">
                  <label>Índice</label>
                  <input
                    className="checkbox"
                    type="checkbox"
                    onClick={() => setIsIndexed(!isIndexed)}
                  />
                </div>
                <div className="checkbox-field">
                  <label>Título</label>
                  <input
                    className="checkbox"
                    type="checkbox"
                    onClick={() => setIsTitled(!isTitled)}
                  />
                </div>
                <div className="checkbox-field">
                  <label>Terminada</label>
                  <input
                    className="checkbox"
                    type="checkbox"
                    onClick={() => setIsCompleted(!isCompleted)}
                  />
                </div>
              </div>
              <div className="actions">
                <button
                  className="document-button"
                  type="submit"
                  onClick={handleCancel}
                >
                  Cancelar
                </button>
                <button
                  className="document-button"
                  type="submit"
                  onClick={scrollHandler}
                  disabled={isLoading}
                >
                  {isLoading ? "Cargando..." : "Subir Documentos"}
                </button>
              </div>
              <div className="note">
                <h6>
                  si requieren una ley certificada, por favor pase por el
                  Departamento de Estado cada vez que hay un costo.
                </h6>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UploadDocumentsES;
