import React, { ChangeEvent } from "react";
import "./HeroText.scss";
import { updateTemplate } from "../../../../apis/updateTemplate";
import { toast } from "react-toastify";

export interface TextProps {
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  handleCloseTitleModal: () => void;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const HeroTitleES: React.FC<TextProps> = ({
  title,
  setTitle,
  handleCloseTitleModal,
  loading,
  setLoading,
}) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newNote = event.target.value;
    setTitle(newNote);
  };

  const onUpdateTitle = () => {
    const update = {
      hero_section_title_es: title,
    };
    setLoading(true);
    updateTemplate(update)
      .then((response) => {
        toast.success("Título actualizado con éxito");
        handleCloseTitleModal();
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Error de actualización del título. Inténtalo de nuevo.");
      });
  };

  return (
    <div className="edit-text">
      <input
        type="text"
        value={title}
        onChange={handleInputChange}
        className="edit-input"
      />
      <button className="edit-button" onClick={onUpdateTitle}>
        {loading ? "Cargando..." : "Actualizar"}
      </button>
    </div>
  );
};

export default HeroTitleES;
