import { useCallback } from "react";
import { Table } from "reactstrap";

import "./Table.scss";
import { AQTableProps } from "./Table.typs";

const AQCheckinTable: React.FC<AQTableProps> = ({
  columns,
  data,
  onUpdateData,
}) => {
  const onUpdateTableData = useCallback(
    (value: any, rowId: string, dataKey: string) => {
      const updatedTableData = data.map((row) => {
        if (row.id === rowId) {
          return {
            ...row,
            [dataKey]: value,
          };
        }
        return row;
      });

      // onUpdateData(updatedTableData);
    },
    [data, onUpdateData]
  );

  return (
    <Table>
      <thead className="rounded-4">
        <tr className="Tableheadingrow">
          {columns.map((column, colIndex) => (
            <th key={column.dataKey} align={column?.align}>
              <span className="table-title">{column.name}</span>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.length >= 1 &&
          data.map((rowData, index) => {
            return (
              <tr key={index} className="Tablerow">
                {columns.map((column, colIndex) => {
                  return (
                    <td
                      width={column?.width}
                      id={`${rowData.tableRowId}-${column.dataKey}`}
                      key={`${rowData.id}-${column.dataKey}`}
                    >
                      {column?.render ? (
                        column.render(
                          rowData[column?.dataKey],
                          rowData,
                          onUpdateTableData
                        )
                      ) : (
                        <span>{rowData[column?.dataKey]}</span>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
      </tbody>
      {data?.length === 0 && (
        <>
          <span className="no-data">No Data</span>
        </>
      )}
    </Table>
  );
};

export default AQCheckinTable;
