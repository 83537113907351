import React, { ChangeEvent, useEffect, useState } from "react";
import "./DownloadList.scss";
import { downloadSingleDocument } from "../../../apis/downloadSingleDocument";
import { toast } from "react-toastify";

export interface TextProps {
  disabledButtons: [];
  handleRemove: any;
  error: any;
  setDisabledButtons: any;
  demandLoading: any;
  setDemandLoading: any;
}

const DownloadListES: React.FC<TextProps> = ({
  handleRemove,
  disabledButtons,
  error,
  setDisabledButtons,
  demandLoading,
  setDemandLoading,
}) => {
  const [controller, setController] = useState(new AbortController());

  const handleOnDemand = async () => {
    setDemandLoading(true);
    try {
      const newController = new AbortController();
      setController(newController);

      const resp: { pdf_url?: string[] } = await downloadSingleDocument(
        disabledButtons,
        newController.signal
      );

      console.log("first", resp);

      if (resp && resp.pdf_url && Array.isArray(resp.pdf_url)) {
        const promises: Promise<void>[] = resp.pdf_url.map(
          (pdfUrl: string, index: number) => {
            console.log("URL", pdfUrl);
            return new Promise((resolve) => {
              setTimeout(() => {
                window.open(pdfUrl, "_blank");
                resolve();
              }, 1000 * index);
            });
          }
        );

        await Promise.all(promises);
        toast.success("Documentos Descargados Exitosamente");
        setDemandLoading(false);
        setDisabledButtons([]);
      } else {
        console.log("Invalid PDF URLs in the response");
        setDemandLoading(false);
      }
    } catch (err: any) {
      if (err.name !== "AbortError") {
        console.error(err);
      }
      setDemandLoading(false);
    }
  };

  const handleCancel = () => {
    controller.abort();
    setDemandLoading(false);
    setController(new AbortController());
    setDisabledButtons([]);
  };

  return (
    <>
      {disabledButtons.length > 0 ? (
        <div className="download-list-container">
          <div className="download-documents-list">
            <h2 className="title">
              Documentos Seleccionados {disabledButtons.length}/3
            </h2>
            <div className="document-list">
              {disabledButtons.map((item: any) => (
                <div key={item.doc_id} className="documents">
                  <p>{item.documentName.slice(0, 12)}</p>
                  <button
                    className="cross-button"
                    onClick={() => handleRemove(item)}
                  >
                    X
                  </button>
                </div>
              ))}
              <p className="error">{error}</p>
            </div>
          </div>
          <div className="download-buttons">
            <button
              className="download-button"
              onClick={handleOnDemand}
              disabled={demandLoading}
            >
              {demandLoading ? "Cargando..." : "Descargar"}
            </button>
            <button className="download-button-diff" onClick={handleCancel}>
              Cancelar
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default DownloadListES;
