import { useCallback, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import "./userdesktop.scss"; 
import AQCheckinTable from "../../../../ReuseableComponents/Table/Table";
import { UserTableColumns } from "./UserTable.uitl";
import { getUsersList } from "../../../../../apis/userApis/getUsersList";
import { deleteUser } from "../../../../../apis/userApis/deleteUser";
import AQModal from "../../../../ReuseableComponents/AQModal/Modal/Modal";
import { getUserData } from "../../../../../apis/userApis/getUser";
import { lockUser } from "../../../../../apis/userApis/lockUser";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { unLockUser } from "../../../../../apis/userApis/unLockUser";
import CreateNewUser from "./createNewUser";
import { toast } from "react-toastify";
import Loader from "../../../../ReuseableComponents/Loader/Loader";
import EditModal from "../../../../ReuseableComponents/Modal/Modal";
import UpdateUser from "../UserManagement/UpdateUser";

type Props = {};

interface UserDataProps {
  id: number;
  type: string;
}
export interface FilterUsers {
  filterUsers: any;
  filterUsersLoading: any;
  usertype: any;
}
const UserDesktop = ({ filterUsers, filterUsersLoading, usertype }: FilterUsers) => {
  const navigate = useNavigate();
  const { data, error } = useQuery(["getUserList"], getUsersList, {
    refetchInterval: 1000,
  });
  const [userData, setUserData] = useState<UserDataProps>({
    id: 0,
    type: "",
  });

  const [showModal, setshowModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [isLock, setIsLock] = useState();
  const [modalEdit, setModalEdit] = useState(false);
  const [editUserID, setEditUserID] = useState();

  const handleOpenModalEdit = () => {
    setTimeout(() => {
      setModalEdit(true);
    }, 500);
  };

  const handleCloseModalEdit = () => {
    setModalEdit(false);
  };

  useEffect(() => {
    if (userData?.id) {
      getUserData(userData?.id)
        .then((resp) => {
          setIsLock(resp?.is_lock);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [isLock, userData]);

  const toggle = () => {
    setModal(!modal);
  };

  const onDelete = useCallback(
    (id: number) => {
      setUserData({
        id: id,
        type: "delete",
      });
      setshowModal(!showModal);
    },
    [showModal, setshowModal]
  );
  const onEdit = useCallback(
    (id: number) => {
      setUserData({
        id: id,
        type: "edit",
      });
      setshowModal(!showModal);
    },
    [showModal, setshowModal]
  );

  const onLock = useCallback(
    (id: number) => {
      setUserData({
        id: id,
        type: "lock",
      });
      setshowModal(!showModal);
    },
    [showModal, setshowModal]
  );

  const onConfirm = () => {
    if (userData?.type === "delete") {
      deleteUser(userData?.id)
        .then((resp) => {
          setshowModal(false);
          toast.success("User deleted succesfully");
        })
        .catch((err) => {
          setshowModal(false);
          if (err) toast.error(err?.response?.data?.error);
        });
    } else if (userData?.type === "edit") {
      getUserData(userData?.id)
        .then((resp) => {
          setshowModal(false);
          setEditUserID(resp?.id);
          handleOpenModalEdit();
        })
        .catch((err) => {
          setshowModal(false);
        });
    } else if (userData?.type === "lock") {
      (isLock ? unLockUser(userData?.id) : lockUser(userData?.id))
        .then((resp) => {
          setshowModal(false);
          toast.success("User status changed succesfully");
        })
        .catch((err) => {
          setshowModal(false);
          if (err) toast.error(err?.response?.data?.error);
        });
    } else return;
  };

  return (
    <div>
      {filterUsersLoading ? <Loader width={300} /> :
       (
          <div className="table">
            <AQCheckinTable
              columns={UserTableColumns(onDelete, onEdit, onLock)}
              data={usertype ? filterUsers?.users : data?.users}
            />
          </div>
      )}

      <Modal
        isOpen={showModal}
        toggle={() => setshowModal(!showModal)}
        className="user-modal"
      >
        <AQModal
          nextBtnText={
              <Button className="confirm-btn" onClick={onConfirm}>
                Yes
              </Button>
          }
          cancelBtnText={
              <Button
                className="cancel-btn"
                onClick={() => setshowModal(false)}
              >
                Cancel
              </Button>
          }
          modal={showModal}
          onConfirm={onConfirm}
          onExitModal={() => setshowModal(!showModal)}
        >
          <span className="user-modal-body">
            Are you sure you want to {userData?.type === "edit" && "Edit"}
            {userData?.type === "delete" && "Delete"}
            {isLock
              ? userData?.type === "lock" && "UnLock"
              : userData.type === "lock" && "Lock"}
            ?
          </span>
        </AQModal>
      </Modal>
      
      <div className="col-md-3">
        {modalEdit && (
          <EditModal
            title="Update User"
            content={<UpdateUser handleCloseModalEdit={handleCloseModalEdit} editUserID={editUserID}/>}
            onClose={handleCloseModalEdit}
          />
        )}
      </div>
    </div>
  );
};

export default UserDesktop;
