import React, { useEffect, useState } from "react";
import "./SearchDocuments.scss";
import { Input } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import Footer from "../Footer";
import RadioButtonsGroupES from "./RadioButtonsES";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import FormSelector from "../FormSelector/FormSelector";
import UpdateHeaderES from "../Header/UpdateHeaderES";
import { useLocation } from "react-router-dom";
import { getDocument } from "../../../../apis/getDocument";
import { updateDocument } from "../../../../apis/updateDocument";
import Modal from "../../../ReuseableComponents/Modal/Modal";
import TextFile from "./TextFile/TextFile";

type Props = {};

export interface SearchData {
  name: string;
  law_number: any;
  type: any;
  year: any;
  status: any;
  date: string;
  file: string;
  regulation_number: any;
  agency: any;
  municipality_number: any;
  municipality: any;
  is_indexed: any;
  is_titled: any;
  is_completed: any;
  file_content: any;
}

const UpdateDocumentsES = (props: Props) => {
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  const navigateLink = new URLSearchParams(location.search).get("link");
  const [editResult, setEditResult] = useState<SearchData | null>(null);

  useEffect(() => {
    if (id) {
      getDocument(id)
        .then((resp) => {
          setEditResult(resp);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id]);

  const [selectedFile, setSelectedFile] = useState(editResult?.file);
  const [isLoading, setIsLoading] = useState(false);
  const [isIndexed, setIsIndexed] = useState(editResult?.is_indexed);
  const [isTitled, setIsTitled] = useState(editResult?.is_titled);
  const [isCompleted, setIsCompleted] = useState(editResult?.is_completed);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleIndexedChange = () => {
    setIsIndexed(!isIndexed);
  };

  const handleTitleChange = () => {
    setIsTitled(!isTitled);
  };

  const handleCompletedChange = () => {
    setIsCompleted(!isCompleted);
  };

  const navigate = useNavigate();
  const bottomRef = React.useRef<HTMLDivElement>(null);

  const scrollHandler = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleCancel = () => {
    if (navigateLink) {
      navigate(navigateLink);
    } else {
      navigate("/");
    }
  };
  const [isRadio, setIsRadio] = useState<any>(1);
  const [generalFields, setGeneralFields] = useState(true);
  const [agenciesFields, setAgenciesFields] = useState(false);
  const [municipalFields, setMunicipalFields] = useState(false);
  const [lawFields, setLawFields] = useState(true);
  const [getTitleModal, setGetTitleModal] = useState(false);
  const [textFile, setTextFile] = useState(editResult?.file_content);

  const handleOpenGetTitleModal = () => {
    setGetTitleModal(true);
  };
  
  const handleCloseGetTitleModal = () => {
    setGetTitleModal(false);
  };

  const [selectedStatus, setSelectedStatus] = useState("");
  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatus(event.target.value);
  };
  const [selectedYear, setSelectedYear] = useState("");
  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(event.target.value);
  };

  const [selectedAgency, setSelectedAgency] = useState("");
  const handleAgencyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedAgency(event.target.value);
  };

  const [selectedMunicipal, setSelectedMunicipal] = useState("");
  const handleMunicipalChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedMunicipal(event.target.value);
  };

  const { handleSubmit, control, setValue } = useForm<SearchData>({
    defaultValues: {
      name: editResult?.name ?? "",
      law_number: editResult?.law_number ?? "",
      type: isRadio,
      year: editResult?.year ?? "",
      status: editResult?.status ?? "",
      date: editResult?.date ?? "",
      regulation_number: editResult?.regulation_number ?? "",
      agency: editResult?.agency ?? "",
      municipality_number: editResult?.municipality_number ?? "",
      municipality: editResult?.municipality ?? "",
      file: editResult?.file ?? "",
    },
    mode: "onChange",
  });

  useEffect(() => {
    // Set the default values when editResult changes
    setValue("name", editResult?.name ?? "");
    setValue("law_number", editResult?.law_number ?? "");
    setValue("type", editResult?.type ?? isRadio);
    setValue("year", editResult?.year ?? "");
    setValue("status", editResult?.status ?? "");
    setValue("date", editResult?.date ?? "");
    setValue("regulation_number", editResult?.regulation_number ?? 0);
    setValue("agency", editResult?.agency ?? "");
    setValue("municipality_number", editResult?.municipality_number ?? 0);
    setValue("municipality", editResult?.municipality ?? "");
    setValue("file", editResult?.file ?? "");
    setIsIndexed(editResult?.is_indexed);
    setIsTitled(editResult?.is_titled);
    setIsCompleted(editResult?.is_completed);
    setSelectedYear(editResult?.year);
    setSelectedStatus(editResult?.status);
    setSelectedAgency(editResult?.agency);
    setSelectedMunicipal(editResult?.municipality);
    setTextFile(editResult?.file_content)

    if (editResult?.type === "Ley") {
      setIsRadio(1);
      setGeneralFields(true);
      setAgenciesFields(false);
      setMunicipalFields(false);
    } else if (editResult?.type === "RC") {
      setIsRadio(2);
      setGeneralFields(true);
      setAgenciesFields(false);
      setMunicipalFields(false);
    } else if (editResult?.type === "Veto") {
      setIsRadio(3);
      setGeneralFields(true);
      setAgenciesFields(false);
      setMunicipalFields(false);
    } else if (editResult?.type === "Reglamento de Agencias") {
      setIsRadio(4);
      setGeneralFields(false);
      setAgenciesFields(true);
      setMunicipalFields(false);
    } else if (editResult?.type === "Reglamento Municipal") {
      setIsRadio(5);
      setGeneralFields(false);
      setAgenciesFields(false);
      setMunicipalFields(true);
    } else if (editResult?.type === "Ordenanza Municipal") {
      setIsRadio(6);
    } else if (editResult?.type === "Resolución Municipal") {
      setIsRadio(7);
    } else {
      setIsRadio(1);
    }
  }, [editResult, setValue]);

  if (!isRadio) {
    return null;
  }
  let searchType: any;
  if (isRadio === 1) {
    searchType = "Ley";
  } else if (isRadio === 2) {
    searchType = "RC";
  } else if (isRadio === 3) {
    searchType = "Veto";
  } else if (isRadio === 4) {
    searchType = "Reglamento de Agencias";
  } else if (isRadio === 5) {
    searchType = "Reglamento Municipal";
  } else if (isRadio === 6) {
    searchType = "Ordenanza Municipal";
  } else if (isRadio === 7) {
    searchType = "Resolución Municipal";
  } else {
    searchType = "LEY";
  }

  const onUpdateDocuments = (data: SearchData) => {
    const update = {
      id: id,
      type: searchType,
      name: data.name,
      law_number: data.law_number,
      year: selectedYear,
      date: data.date,
      file: selectedFile,
      status: selectedStatus,
      regulation_number: data.regulation_number ?? 0,
      agency: selectedAgency,
      municipality_number: data.municipality_number ?? 0,
      municipality: selectedMunicipal,
      is_indexed: isIndexed,
      is_titled: isTitled,
      is_completed: isCompleted,
    };
    setIsLoading(true);
    updateDocument(update)
      .then((response) => {
        toast.success("Documento actualizado con éxito");
        setIsLoading(false);
        if (navigateLink) {
          navigate(navigateLink);
        } else {
          navigate("/");
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.error(
          "Documento de actualización de errores. Inténtalo de nuevo."
        );
        setIsLoading(false);
      });
  };
  // const editStatusOptions = [
  //   {
  //     label: editResult?.status ?? "Seleccione Estatus",
  //     value: editResult?.status ?? "Seleccione Estatus",
  //   },
  //   { label: "Activa", value: "Activa" },
  //   { label: "Enmendada", value: "Enmendada" },
  //   { label: "Derogada", value: "Derogada" },
  //   { label: "Ninguna", value: "Ninguna" },
  // ];
  
  const handlePreTitle = () => {
    setValue("name", searchType + " " + editResult?.law_number + "-" + editResult?.year + "," + " " + editResult?.date + "," + " " + editResult?.name);
  };
  let editStatusOptions = [
    { label: "Activa", value: "Activa" },
    { label: "Enmendada", value: "Enmendada" },
    { label: "Derogada", value: "Derogada" },
    { label: "", value: "" },
  ];

  switch (editResult?.status) {
    case "Activa":
      editStatusOptions = [
        { label: "Activa", value: "Activa" },
        { label: "Enmendada", value: "Enmendada" },
        { label: "Derogada", value: "Derogada" },
        { label: "", value: "" },
      ];
      break;

    case "Enmendada":
      editStatusOptions = [
        { label: "Enmendada", value: "Enmendada" },
        { label: "Activa", value: "Activa" },
        { label: "Derogada", value: "Derogada" },
        { label: "", value: "" },
      ];
      break;

    case "Derogada":
      editStatusOptions = [
        { label: "Derogada", value: "Derogada" },
        { label: "Activa", value: "Activa" },
        { label: "Enmendada", value: "Enmendada" },
        { label: "", value: "" },
      ];
      break;

    default:
      editStatusOptions = [
        { label: "", value: "" },
        { label: "Activa", value: "Activa" },
        { label: "Enmendada", value: "Enmendada" },
        { label: "Derogada", value: "Derogada" },
      ];
      break;
  }

  const editYears = [
    {
      label: editResult?.year ?? "Seleccionar Año",
      value: editResult?.year ?? "Seleccionar Año",
    },
    { label: "1901", value: "1901" },
    { label: "1902", value: "1902" },
    { label: "1903", value: "1903" },
    { label: "1904", value: "1904" },
    { label: "1905", value: "1905" },
    { label: "1906", value: "1906" },
    { label: "1907", value: "1907" },
    { label: "1908", value: "1908" },
    { label: "1909", value: "1909" },
    { label: "1910", value: "1910" },
    { label: "1911", value: "1911" },
    { label: "1912", value: "1912" },
    { label: "1913", value: "1913" },
    { label: "1914", value: "1914" },
    { label: "1915", value: "1915" },
    { label: "1916", value: "1916" },
    { label: "1917", value: "1917" },
    { label: "1918", value: "1918" },
    { label: "1919", value: "1919" },
    { label: "1920", value: "1920" },
    { label: "1921", value: "1921" },
    { label: "1922", value: "1922" },
    { label: "1923", value: "1923" },
    { label: "1924", value: "1924" },
    { label: "1925", value: "1925" },
    { label: "1926", value: "1926" },
    { label: "1927", value: "1927" },
    { label: "1928", value: "1928" },
    { label: "1929", value: "1929" },
    { label: "1930", value: "1930" },
    { label: "1931", value: "1931" },
    { label: "1932", value: "1932" },
    { label: "1933", value: "1933" },
    { label: "1934", value: "1934" },
    { label: "1935", value: "1935" },
    { label: "1936", value: "1936" },
    { label: "1937", value: "1937" },
    { label: "1938", value: "1938" },
    { label: "1939", value: "1939" },
    { label: "1940", value: "1940" },
    { label: "1941", value: "1941" },
    { label: "1942", value: "1942" },
    { label: "1943", value: "1943" },
    { label: "1944", value: "1944" },
    { label: "1945", value: "1945" },
    { label: "1946", value: "1946" },
    { label: "1947", value: "1947" },
    { label: "1948", value: "1948" },
    { label: "1949", value: "1949" },
    { label: "1950", value: "1950" },
    { label: "1951", value: "1951" },
    { label: "1952", value: "1952" },
    { label: "1953", value: "1953" },
    { label: "1954", value: "1954" },
    { label: "1955", value: "1955" },
    { label: "1956", value: "1956" },
    { label: "1957", value: "1957" },
    { label: "1958", value: "1958" },
    { label: "1959", value: "1959" },
    { label: "1960", value: "1960" },
    { label: "1961", value: "1961" },
    { label: "1962", value: "1962" },
    { label: "1963", value: "1963" },
    { label: "1964", value: "1964" },
    { label: "1965", value: "1965" },
    { label: "1966", value: "1966" },
    { label: "1967", value: "1967" },
    { label: "1968", value: "1968" },
    { label: "1969", value: "1969" },
    { label: "1970", value: "1970" },
    { label: "1971", value: "1971" },
    { label: "1972", value: "1972" },
    { label: "1973", value: "1973" },
    { label: "1974", value: "1974" },
    { label: "1975", value: "1975" },
    { label: "1976", value: "1976" },
    { label: "1977", value: "1977" },
    { label: "1978", value: "1978" },
    { label: "1979", value: "1979" },
    { label: "1980", value: "1980" },
    { label: "1981", value: "1981" },
    { label: "1982", value: "1982" },
    { label: "1983", value: "1983" },
    { label: "1984", value: "1984" },
    { label: "1985", value: "1985" },
    { label: "1986", value: "1986" },
    { label: "1987", value: "1987" },
    { label: "1988", value: "1988" },
    { label: "1989", value: "1989" },
    { label: "1990", value: "1990" },
    { label: "1991", value: "1991" },
    { label: "1992", value: "1992" },
    { label: "1993", value: "1993" },
    { label: "1994", value: "1994" },
    { label: "1995", value: "1995" },
    { label: "1996", value: "1996" },
    { label: "1997", value: "1997" },
    { label: "1998", value: "1998" },
    { label: "1999", value: "1999" },
    { label: "2000", value: "2000" },
    { label: "2001", value: "2001" },
    { label: "2002", value: "2002" },
    { label: "2003", value: "2003" },
    { label: "2004", value: "2004" },
    { label: "2005", value: "2005" },
    { label: "2006", value: "2006" },
    { label: "2007", value: "2007" },
    { label: "2008", value: "2008" },
    { label: "2009", value: "2009" },
    { label: "2010", value: "2010" },
    { label: "2011", value: "2011" },
    { label: "2012", value: "2012" },
    { label: "2013", value: "2013" },
    { label: "2014", value: "2014" },
    { label: "2015", value: "2015" },
    { label: "2016", value: "2016" },
    { label: "2017", value: "2017" },
    { label: "2018", value: "2018" },
    { label: "2019", value: "2019" },
    { label: "2020", value: "2020" },
    { label: "2021", value: "2021" },
    { label: "2022", value: "2022" },
    { label: "2023", value: "2023" },
    { label: "2024", value: "2024" }
  ];

  const editAgenciesOptions = [
    {
      label: editResult?.agency ?? "Seleccionar Agencia",
      value: editResult?.agency ?? "Seleccionar Agencia",
    },
    {
      label: "Adm. de Facilidades Industriales (AFICA))",
      value: "Adm. de Facilidades Industriales (AFICA))",
    },
    {
      label: "Adm. de Servicio Salud Mental y Contra la Adicción",
      value: "Adm. de Servicio Salud Mental y Contra la Adicción",
    },
    {
      label: "Adm. de Servicios y Desarrollo Agropecuario",
      value: "Adm. de Servicios y Desarrollo Agropecuario",
    },
    {
      label: "Adm. Para la Rehabilitación de las Comunidades",
      value: "Adm. Para la Rehabilitación de las Comunidades",
    },
    {
      label: "Administración de Asuntos de Energía",
      value: "Administración de Asuntos de Energía",
    },
    {
      label: "Administración de Asuntos Federales",
      value: "Administración de Asuntos Federales",
    },
    {
      label: "Administración de Compensaciones por Accidentes",
      value: "Administración de Compensaciones por Accidentes",
    },
    {
      label: "Administración de Corrección",
      value: "Administración de Corrección",
    },
    {
      label: "Administración de Derechos al Trabajo",
      value: "Administración de Derechos al Trabajo",
    },
    {
      label: "Administración de Desarrollo y Mejoras de Vivienda",
      value: "Administración de Desarrollo y Mejoras de Vivienda",
    },
    {
      label: "Administración de Facilidades de Salud",
      value: "Administración de Facilidades de Salud",
    },
    {
      label: "Administración de Familias y Niños",
      value: "Administración de Familias y Niños",
    },
    {
      label: "Administración de Fomento Cooperativo",
      value: "Administración de Fomento Cooperativo",
    },
    {
      label: "Administración de Fomento Económico",
      value: "Administración de Fomento Económico",
    },
    {
      label: "Administración de Instituciones Juveniles",
      value: "Administración de Instituciones Juveniles",
    },
    {
      label: "Administración de la Industria del Deporte Hípico",
      value: "Administración de la Industria del Deporte Hípico",
    },
    {
      label: "Administración de los Sistemas de Retiro",
      value: "Administración de los Sistemas de Retiro",
    },
    {
      label: "Administración de Recursos Naturales",
      value: "Administración de Recursos Naturales",
    },
    {
      label: "Administración de Reglamentos y Permisos",
      value: "Administración de Reglamentos y Permisos",
    },
    {
      label: "Administración de Rehabilitación Vocacional",
      value: "Administración de Rehabilitación Vocacional",
    },
    {
      label: "Administración de Seguros de Salud",
      value: "Administración de Seguros de Salud",
    },
    {
      label:
        "Administración de Servicios de Salud Mental y Contra la Adicción (ASSMCA))",
      value:
        "Administración de Servicios de Salud Mental y Contra la Adicción (ASSMCA))",
    },
    {
      label: "Administración de Servicios Generales",
      value: "Administración de Servicios Generales",
    },
    {
      label: "Administración de Servicios Médicos",
      value: "Administración de Servicios Médicos",
    },
    {
      label: "Administración de Terrenos",
      value: "Administración de Terrenos",
    },
    {
      label: "Administración de Vivienda Pública",
      value: "Administración de Vivienda Pública",
    },
    {
      label: "Administración para el Adiestramientos de Futuros",
      value: "Administración para el Adiestramientos de Futuros",
    },
    {
      label: "Administración para el Cuidado y Desarrollo",
      value: "Administración para el Cuidado y Desarrollo",
    },
    {
      label: "Administración para el Sustento de Menores",
      value: "Administración para el Sustento de Menores",
    },
    {
      label: "Administración y Trans. de los Recursos Humanos (OATRH)",
      value: "Administración y Trans. de los Recursos Humanos (OATRH)",
    },
    {
      label: "Agencia Estatal para el Manejo de Emergencias",
      value: "Agencia Estatal para el Manejo de Emergencias",
    },
    {
      label: "Agencias de Financiamiento Municipal",
      value: "Agencias de Financiamiento Municipal",
    },
    { label: "Agricultura Comercial", value: "Agricultura Comercial" },
    {
      label: "Autoridad de Acueductos y Alcantarillados",
      value: "Autoridad de Acueductos y Alcantarillados",
    },
    {
      label: "Autoridad de Asesoría Financiera y Agencia Fiscal de Puerto Rico",
      value: "Autoridad de Asesoría Financiera y Agencia Fiscal de Puerto Rico",
    },
    {
      label: "Autoridad de Carreteras y Transportación",
      value: "Autoridad de Carreteras y Transportación",
    },
    {
      label: "Autoridad de Conservación y Desarrollo de Culebra",
      value: "Autoridad de Conservación y Desarrollo de Culebra",
    },
    {
      label: "Autoridad de Desperdicios Sólidos",
      value: "Autoridad de Desperdicios Sólidos",
    },
    {
      label: "Autoridad de Edificios Públicos",
      value: "Autoridad de Edificios Públicos",
    },
    {
      label: "Autoridad de Energía Eléctrica",
      value: "Autoridad de Energía Eléctrica",
    },
    { label: "Autoridad de los Puertos", value: "Autoridad de los Puertos" },
    {
      label: "Autoridad de Telefonos de Puerto Rico",
      value: "Autoridad de Telefonos de Puerto Rico",
    },
    {
      label: "Autoridad de Tierras de Puerto Rico",
      value: "Autoridad de Tierras de Puerto Rico",
    },
    {
      label: "Autoridad de Transporte Integrado de Puerto Rico",
      value: "Autoridad de Transporte Integrado de Puerto Rico",
    },
    {
      label: "Autoridad de Transporte Marítimo",
      value: "Autoridad de Transporte Marítimo",
    },
    {
      label: "Autoridad del Distrito del Centro de Convenciones de Puerto Rico",
      value: "Autoridad del Distrito del Centro de Convenciones de Puerto Rico",
    },
    {
      label: "Autoridad del Puerto de Ponce",
      value: "Autoridad del Puerto de Ponce",
    },
    {
      label: "Autoridad Metropolitana de Autobuses",
      value: "Autoridad Metropolitana de Autobuses",
    },
    {
      label: "Autoridad para el Financiamiento de la Infraestructura",
      value: "Autoridad para el Financiamiento de la Infraestructura",
    },
    {
      label: "Autoridad para el Financiamiento de la Vivienda",
      value: "Autoridad para el Financiamiento de la Vivienda",
    },
    {
      label: "Autoridad para el Redesarrollo Local de Roosevelt Roads",
      value: "Autoridad para el Redesarrollo Local de Roosevelt Roads",
    },
    {
      label: "Autoridad para las Alianzas Públicas y Privadas",
      value: "Autoridad para las Alianzas Públicas y Privadas",
    },
    {
      label: "Banco de Cooperativas de Puerto Rico",
      value: "Banco de Cooperativas de Puerto Rico",
    },
    {
      label: "Banco de Desarrollo Económico para Puerto Rico",
      value: "Banco de Desarrollo Económico para Puerto Rico",
    },
    {
      label: "Banco Gubernamental de Fometo para Puerto Rico",
      value: "Banco Gubernamental de Fometo para Puerto Rico",
    },
    {
      label: "Centro Comprensivo de Cáncer",
      value: "Centro Comprensivo de Cáncer",
    },
    {
      label: "Centro de Diabetes para Puerto Rico",
      value: "Centro de Diabetes para Puerto Rico",
    },
    {
      label: "Centro de Recaudación de Ingresos Municipales",
      value: "Centro de Recaudación de Ingresos Municipales",
    },
    {
      label: "Colegio Universitario de Justicia Criminal",
      value: "Colegio Universitario de Justicia Criminal",
    },
    {
      label: "Comisión Apelativa del Sistema de Administración",
      value: "Comisión Apelativa del Sistema de Administración",
    },
    {
      label: "Comisión de Derechos Civiles",
      value: "Comisión de Derechos Civiles",
    },
    {
      label: "Comisión de Desarrollo Cooperativo de Puerto Rico",
      value: "Comisión de Desarrollo Cooperativo de Puerto Rico",
    },
    {
      label: "Comisión de Investigación, Procesamiento",
      value: "Comisión de Investigación, Procesamiento",
    },
    {
      label: "Comisión de Juegos del Gobierno de Puerto Rico",
      value: "Comisión de Juegos del Gobierno de Puerto Rico",
    },
    {
      label: "Comisión de la Policía",
      value: "Comisión de la Policía",
    },
    {
      label: "Comisión de Prácticaje de Puerto Rico",
      value: "Comisión de Prácticaje de Puerto Rico",
    },
    {
      label: "Comisión de Protección y Fortalecimiento de la Familia",
      value: "Comisión de Protección y Fortalecimiento de la Familia",
    },
    {
      label: "Comisión de Relaciones del Trabajo del Servicio",
      value: "Comisión de Relaciones del Trabajo del Servicio",
    },
    {
      label: "Comisión de Seguridad en el Tránsito",
      value: "Comisión de Seguridad en el Tránsito",
    },
    {
      label: "Comisión de Seguridad y Protección Pública",
      value: "Comisión de Seguridad y Protección Pública",
    },
    {
      label: "Comisión de Servicio Público",
      value: "Comisión de Servicio Público",
    },
    {
      label: "Comisión del Puerto de Mayagüez",
      value: "Comisión del Puerto de Mayagüez",
    },
    {
      label: "Comisión Especial Permanente de los Sistemas de Retiro",
      value: "Comisión Especial Permanente de los Sistemas de Retiro",
    },
    {
      label: "Comisión Estatal de Elecciones",
      value: "Comisión Estatal de Elecciones",
    },
    {
      label: "Comisión Industrial de Puerto Rico",
      value: "Comisión Industrial de Puerto Rico",
    },
    {
      label: "Comisión para Combatir el Crimen",
      value: "Comisión para Combatir el Crimen",
    },
    {
      label: "Comisión para Ventilar Querellas Municipales",
      value: "Comisión para Ventilar Querellas Municipales",
    },
    {
      label: "Comisionado de Instituciones Financieras",
      value: "Comisionado de Instituciones Financieras",
    },
    {
      label: "Comité de Evaluación Disposición de Bienes Inmueble",
      value: "Comité de Evaluación Disposición de Bienes Inmueble",
    },
    {
      label: "Compañía de Comercio y Exportación de Puerto Rico",
      value: "Compañía de Comercio y Exportación de Puerto Rico",
    },
    {
      label: "Compañía de Desarrollo Comercial",
      value: "Compañía de Desarrollo Comercial",
    },
    {
      label: "Compañía de Desarrollo Cooperativo",
      value: "Compañía de Desarrollo Cooperativo",
    },
    {
      label: "Compañía de Fomento Industrial",
      value: "Compañía de Fomento Industrial",
    },
    {
      label: "Compañía de Parques Nacionales de Puerto Rico",
      value: "Compañía de Parques Nacionales de Puerto Rico",
    },
    {
      label: "Compañía de Turismo",
      value: "Compañía de Turismo",
    },
    {
      label: "Compañía para el Desarrollo Integrar Península de Cantera",
      value: "Compañía para el Desarrollo Integrar Península de Cantera",
    },
    {
      label: "Consejo de Desarrollo Ocupacional y Rec. Humanos",
      value: "Consejo de Desarrollo Ocupacional y Rec. Humanos",
    },
    {
      label: "Consejo de Educación Superior de Puerto Rico",
      value: "Consejo de Educación Superior de Puerto Rico",
    },
    {
      label: "Consejo de Educación Técnico Ocupacional",
      value: "Consejo de Educación Técnico Ocupacional",
    },
    {
      label: "Consejo General de Educación",
      value: "Consejo General de Educación",
    },
    {
      label: "Consejo para la Protección del Patrimonio",
      value: "Consejo para la Protección del Patrimonio",
    },
    {
      label: "Corporación Azucarera de Puerto Rico",
      value: "Corporación Azucarera de Puerto Rico",
    },
    {
      label: "Corporación de Crédito y Desarrollo Comercial y Agrícola",
      value: "Corporación de Crédito y Desarrollo Comercial y Agrícola",
    },
    {
      label: "Corporación de Empresas de Adiestramiento y Trabajo",
      value: "Corporación de Empresas de Adiestramiento y Trabajo",
    },
    {
      label: "Corporación de la Orquesta Sinfónica",
      value: "Corporación de la Orquesta Sinfónica",
    },
    {
      label: "Corporación de las Artes Musicales",
      value: "Corporación de las Artes Musicales",
    },
    {
      label: "Corporación de Puerto Rico para la Difusión Pública",
      value: "Corporación de Puerto Rico para la Difusión Pública",
    },
    {
      label: "Corporación de Recursos Minerales",
      value: "Corporación de Recursos Minerales",
    },
    {
      label: "Corporación de Seguros Agrícolas",
      value: "Corporación de Seguros Agrícolas",
    },
    {
      label: "Corporación del Centro Cardiovascular de Puerto Rico y el Caribe",
      value: "Corporación del Centro Cardiovascular de Puerto Rico y el Caribe",
    },
    {
      label: "Corporación del Centro de Bellas Artes",
      value: "Corporación del Centro de Bellas Artes",
    },
    {
      label: "Corporación del Conservatorio de Música",
      value: "Corporación del Conservatorio de Música",
    },
    {
      label: "Corporación del Fondo del Seguro del Estado",
      value: "Corporación del Fondo del Seguro del Estado",
    },
    {
      label: "Corporación del Proyecto del Caño Martín Peña",
      value: "Corporación del Proyecto del Caño Martín Peña",
    },
    {
      label: "Corporación para el Desarrollo del Cine",
      value: "Corporación para el Desarrollo del Cine",
    },
    {
      label: "Corporación para el Desarrollo Rural",
      value: "Corporación para el Desarrollo Rural",
    },
    {
      label: "Corporación para el Financiamiento Público",
      value: "Corporación para el Financiamiento Público",
    },
    {
      label: "Corporación para la Supervisión y Seguros de Cooperativa",
      value: "Corporación para la Supervisión y Seguros de Cooperativa",
    },
    {
      label: "Corporación Pública para la Supervisión y Seguro",
      value: "Corporación Pública para la Supervisión y Seguro",
    },
    {
      label: "Cuerpo de Bomberos de Puerto Rico",
      value: "Cuerpo de Bomberos de Puerto Rico",
    },
    {
      label: "Cuerpo de Emergencias Médicas",
      value: "Cuerpo de Emergencias Médicas",
    },
    {
      label: "Defensoría de las Personas con Impedimentos",
      value: "Defensoría de las Personas con Impedimentos",
    },
    {
      label: "Departamento de Agricultura",
      value: "Departamento de Agricultura",
    },
    {
      label: "Departamento de Asuntos Comunidad Puertoriqueña",
      value: "Departamento de Asuntos Comunidad Puertoriqueña",
    },
    {
      label: "Departamento de Asuntos del Consumidor",
      value: "Departamento de Asuntos del Consumidor",
    },
    {
      label: "Departamento de Corrección y Rehabilitación",
      value: "Departamento de Corrección y Rehabilitación",
    },
    {
      label: "Departamento de Desarrollo Económico y Comercio",
      value: "Departamento de Desarrollo Económico y Comercio",
    },
    {
      label: "Departamento de Educación",
      value: "Departamento de Educación",
    },
    {
      label: "Departamento de Estado",
      value: "Departamento de Estado",
    },
    {
      label: "Departamento de Hacienda",
      value: "Departamento de Hacienda",
    },
    {
      label: "Departamento de Justicia",
      value: "Departamento de Justicia",
    },
    {
      label: "Departamento de la Familia",
      value: "Departamento de la Familia",
    },
    {
      label: "Departamento de la Vivienda",
      value: "Departamento de la Vivienda",
    },
    {
      label: "Departamento de Recreación y Deportes",
      value: "Departamento de Recreación y Deportes",
    },
    {
      label: "Departamento de Recursos Naturales y Ambientales",
      value: "Departamento de Recursos Naturales y Ambientales",
    },
    { label: "Departamento de Salud", value: "Departamento de Salud" },
    {
      label: "Departamento de Seguridad Pública",
      value: "Departamento de Seguridad Pública",
    },
    {
      label: "Departamento de Transportación y Obras Públicas",
      value: "Departamento de Transportación y Obras Públicas",
    },
    {
      label: "Departamento del Trabajo y Recursos Humanos",
      value: "Departamento del Trabajo y Recursos Humanos",
    },
    {
      label: "Distrito del Centro de Convenciones",
      value: "Distrito del Centro de Convenciones",
    },
    {
      label: "Escuela de Artes Plasticas",
      value: "Escuela de Artes Plasticas",
    },
    {
      label: "Fideicomiso Institucional de la Guardia Nacional de Puerto Rico",
      value: "Fideicomiso Institucional de la Guardia Nacional de Puerto Rico",
    },
    {
      label: "Fideicomiso Perpetuo Comunidades Especiales",
      value: "Fideicomiso Perpetuo Comunidades Especiales",
    },
    {
      label: "Fondo de Innovación para el Desarrollo Agrícola",
      value: "Fondo de Innovación para el Desarrollo Agrícola",
    },
    {
      label: "Fondo Puertorriqueño para el Financiamiento",
      value: "Fondo Puertorriqueño para el Financiamiento",
    },
    {
      label: "Guardia Nacional de Puerto Rico",
      value: "Guardia Nacional de Puerto Rico",
    },
    {
      label: "Instituto de Ciencias Forenses de Puerto Rico",
      value: "Instituto de Ciencias Forenses de Puerto Rico",
    },
    {
      label: "Instituto de Cultura Puertorriqueña",
      value: "Instituto de Cultura Puertorriqueña",
    },
    {
      label: "Instituto de Estadísticas de Puerto Rico",
      value: "Instituto de Estadísticas de Puerto Rico",
    },
    {
      label: "Instituto de Literatura Puertorriqueña (UPR)",
      value: "Instituto de Literatura Puertorriqueña (UPR)",
    },
    {
      label: "Junta Apelaciones Construcciones y Lotificaciones",
      value: "Junta Apelaciones Construcciones y Lotificaciones",
    },
    {
      label: "Junta Azucarera",
      value: "Junta Azucarera",
    },
    {
      label: "Junta Central de Reconsideración de Subastas",
      value: "Junta Central de Reconsideración de Subastas",
    },
    {
      label: "Junta de Apelación de Sistema de Educación Pública",
      value: "Junta de Apelación de Sistema de Educación Pública",
    },
    {
      label: "Junta de Calidad Ambiental",
      value: "Junta de Calidad Ambiental",
    },
    {
      label: "Junta de Gobierno - Universidad de Puerto Rico",
      value: "Junta de Gobierno - Universidad de Puerto Rico",
    },
    {
      label: "Junta de Gobierno del  Servicio 9-1-1",
      value: "Junta de Gobierno del  Servicio 9-1-1",
    },
    {
      label: "Junta de Libertad Bajo Palabra",
      value: "Junta de Libertad Bajo Palabra",
    },
    {
      label: "Junta de Planificación de Puerto Rico",
      value: "Junta de Planificación de Puerto Rico",
    },
    {
      label: "Junta de Preferencia para Compras del Gobierno",
      value: "Junta de Preferencia para Compras del Gobierno",
    },
    {
      label: "Junta de Relaciones del Trabajo",
      value: "Junta de Relaciones del Trabajo",
    },
    {
      label: "Junta de Síndicos (UPR)",
      value: "Junta de Síndicos (UPR)",
    },
    {
      label: "Junta del Centro Bancario Internacional",
      value: "Junta del Centro Bancario Internacional",
    },
    { label: "Junta Financiera", value: "Junta Financiera" },
    { label: "Junta Hipica", value: "Junta Hipica" },
    {
      label: "Junta Interangencial para Combatir la Apropiación",
      value: "Junta Interangencial para Combatir la Apropiación",
    },
    {
      label: "Junta Reglamentadora de Servicios Públicos de Puerto Rico",
      value: "Junta Reglamentadora de Servicios Públicos de Puerto Rico",
    },
    {
      label: "Junta Reguladora de Tasas de Interés",
      value: "Junta Reguladora de Tasas de Interés",
    },
    {
      label: "Junta Reguladora de Ventas a Plazos",
      value: "Junta Reguladora de Ventas a Plazos",
    },
    {
      label: "Junta Revisora de Permisos y Uso de Terrenos",
      value: "Junta Revisora de Permisos y Uso de Terrenos",
    },
    {
      label: "Negociado de Energía de Puerto Rico",
      value: "Negociado de Energía de Puerto Rico",
    },
    {
      label: "Negociado de Investigaciones Especiales",
      value: "Negociado de Investigaciones Especiales",
    },
    {
      label: "Negociado de la Policía de Puerto Rico",
      value: "Negociado de la Policía de Puerto Rico",
    },
    {
      label: "Negociado de Telecomunicaciones",
      value: "Negociado de Telecomunicaciones",
    },
    {
      label:
        "Negociado de Transporte y Otros Servicios Públicos de Puerto Rico",
      value:
        "Negociado de Transporte y Otros Servicios Públicos de Puerto Rico",
    },
    {
      label:
        "Oficina Coordinador General para el Financiamiento Socioeconómico y la Autogestión",
      value:
        "Oficina Coordinador General para el Financiamiento Socioeconómico y la Autogestión",
    },
    {
      label: "Oficina de Administración de las Procuradurías",
      value: "Oficina de Administración de las Procuradurías",
    },
    {
      label:
        "Oficina de Administración y Transformación de los Recursos Humanos del Gobierno de Puerto Rico",
      value:
        "Oficina de Administración y Transformación de los Recursos Humanos del Gobierno de Puerto Rico",
    },
    {
      label: "Oficina de Asuntos de la Juventud",
      value: "Oficina de Asuntos de la Juventud",
    },
    {
      label: "Oficina de Asuntos Laborales",
      value: "Oficina de Asuntos Laborales",
    },
    {
      label: "Oficina de Comunidades Especiales de Puerto Rico",
      value: "Oficina de Comunidades Especiales de Puerto Rico",
    },
    {
      label: "Oficina de Control de Drogas de Puerto Rico",
      value: "Oficina de Control de Drogas de Puerto Rico",
    },
    {
      label: "Oficina de Ética Gubernamental",
      value: "Oficina de Ética Gubernamental",
    },
    {
      label: "Oficina de Gerencia de Permisos",
      value: "Oficina de Gerencia de Permisos",
    },
    {
      label: "Oficina de Gerencia y Presupuesto",
      value: "Oficina de Gerencia y Presupuesto",
    },
    {
      label: "Oficina de la Industria Lechera",
      value: "Oficina de la Industria Lechera",
    },
    {
      label: "Oficina de la Procuradora de las Mujeres",
      value: "Oficina de la Procuradora de las Mujeres",
    },
    {
      label: "Oficina de la Procuradora del Paciente",
      value: "Oficina de la Procuradora del Paciente",
    },
    {
      label: "Oficina de las Comunidades Especiales",
      value: "Oficina de las Comunidades Especiales",
    },
    {
      label:
        "Oficina de Reglamentación y Certificación de Profesionales de la Salud",
      value:
        "Oficina de Reglamentación y Certificación de Profesionales de la Salud",
    },
    {
      label: "Oficina de Servicios con Antelación al Juicio",
      value: "Oficina de Servicios con Antelación al Juicio",
    },
    {
      label: "Oficina del Comisionado de Asuntos Municipales",
      value: "Oficina del Comisionado de Asuntos Municipales",
    },
    {
      label: "Oficina del Comisionado de Instituciones Financieras",
      value: "Oficina del Comisionado de Instituciones Financieras",
    },
    {
      label: "Oficina del Comisionado de Seguros",
      value: "Oficina del Comisionado de Seguros",
    },
    {
      label: "Oficina del Contralor",
      value: "Oficina del Contralor",
    },
    {
      label: "Oficina del Contralor Electoral",
      value: "Oficina del Contralor Electoral",
    },
    {
      label: "Oficina del Gobernador",
      value: "Oficina del Gobernador",
    },
    {
      label: "Oficina del Inspector de Cooperativas de Puerto Rico",
      value: "Oficina del Inspector de Cooperativas de Puerto Rico",
    },
    {
      label: "Oficina del Inspector General de Permisos",
      value: "Oficina del Inspector General de Permisos",
    },
    {
      label: "Oficina del Inspector General de Puerto Rico",
      value: "Oficina del Inspector General de Puerto Rico",
    },
    {
      label: "Oficina del Procurador de Personas con Impedimentos",
      value: "Oficina del Procurador de Personas con Impedimentos",
    },
    {
      label: "Oficina del Procurador del Veterano Puertorriqueño",
      value: "Oficina del Procurador del Veterano Puertorriqueño",
    },
    {
      label: "Oficina el Procurador del Ciudadano",
      value: "Oficina el Procurador del Ciudadano",
    },
    {
      label: "Oficina Estatal de Conservación Histórica",
      value: "Oficina Estatal de Conservación Histórica",
    },
    {
      label: "Oficina Estatal de Política Pública Energética",
      value: "Oficina Estatal de Política Pública Energética",
    },
    {
      label: "Oficina para Administración de los Activos de la Extinta CRUV",
      value: "Oficina para Administración de los Activos de la Extinta CRUV",
    },
    {
      label: "Oficina para Coordinación de Ayuda y Servicio",
      value: "Oficina para Coordinación de Ayuda y Servicio",
    },
    {
      label: "Oficina para el Desarrollo Comunal",
      value: "Oficina para el Desarrollo Comunal",
    },
    {
      label:
        "Oficina para el Desarrollo Socioeconómico y Comunitario de Puerto Rico (ODSEC)",
      value:
        "Oficina para el Desarrollo Socioeconómico y Comunitario de Puerto Rico (ODSEC)",
    },
    {
      label: "Oficina para el Financiamiento Socioeconomico",
      value: "Oficina para el Financiamiento Socioeconomico",
    },
    {
      label: "Oficina para el Mejoramiento de las Escuelas Pública",
      value: "Oficina para el Mejoramiento de las Escuelas Pública",
    },
    {
      label: "Oficina para los Asuntos de la Vejez",
      value: "Oficina para los Asuntos de la Vejez",
    },
    {
      label: "Oficina Procuradora de las Personas de Edad Avanzada",
      value: "Oficina Procuradora de las Personas de Edad Avanzada",
    },
    {
      label: "Panel sobre el Fiscal Especial Independiente",
      value: "Panel sobre el Fiscal Especial Independiente",
    },
    {
      label: "Puerto Rico Statehood Commission",
      value: "Puerto Rico Statehood Commission",
    },
    {
      label: "Registro Central de Cáncer",
      value: "Registro Central de Cáncer",
    },
    {
      label: "Sistema de Retiro para Maestros",
      value: "Sistema de Retiro para Maestros",
    },
    {
      label: "Tribunal Examinador de Médicos",
      value: "Tribunal Examinador de Médicos",
    },
  ];

  const editMunicipalOptions = [
    {
      label: editResult?.municipality ?? "Seleccione Municipio",
      value: editResult?.municipality ?? "Seleccione Municipio",
    },
    { label: "Adjuntas", value: "Adjuntas" },
    { label: "Aguada", value: "Aguada" },
    { label: "Aguadilla", value: "Aguadilla" },
    { label: "Aguas Buenas", value: "Aguas Buenas" },
    { label: "Aibonito", value: "Aibonito" },
    { label: "Arecibo", value: "Arecibo" },
    { label: "Arroyo", value: "Arroyo" },
    { label: "Añasco", value: "Añasco" },
    { label: "Barceloneta", value: "Barceloneta" },
    { label: "Barranquitas", value: "Barranquitas" },
    { label: "Bayamón", value: "Bayamón" },
    { label: "Cabo Rojo", value: "Cabo Rojo" },
    { label: "Caguas", value: "Caguas" },
    { label: "Camuy", value: "Camuy" },
    { label: "Canóvanas", value: "Canóvanas" },
    { label: "Carolina", value: "Carolina" },
    { label: "Cataño", value: "Cataño" },
    { label: "Cayey", value: "Cayey" },
    { label: "Ceiba", value: "Ceiba" },
    { label: "Ciales", value: "Ciales" },
    { label: "Cidra", value: "Cidra" },
    { label: "Coamo", value: "Coamo" },
    { label: "Comerío", value: "Comerío" },
    { label: "Corozal", value: "Corozal" },
    { label: "Culebra", value: "Culebra" },
    { label: "Dorado", value: "Dorado" },
    { label: "Fajardo", value: "Fajardo" },
    { label: "Florida", value: "Florida" },
    { label: "Guayama", value: "Guayama" },
    { label: "Guayanilla", value: "Guayanilla" },
    { label: "Guaynabo", value: "Guaynabo" },
    { label: "Gurabo", value: "Gurabo" },
    { label: "Guánica", value: "Guánica" },
    { label: "Hatillo", value: "Hatillo" },
    { label: "Hormigueros", value: "Hormigueros" },
    { label: "Humacao", value: "Humacao" },
    { label: "Isabela", value: "Isabela" },
    { label: "Jayuya", value: "Jayuya" },
    { label: "Juana Díaz", value: "Juana Díaz" },
    { label: "Juncos", value: "Juncos" },
    { label: "Lajas", value: "Lajas" },
    { label: "Lares", value: "Lares" },
    { label: "Las Marías", value: "Las Marías" },
    { label: "Las Piedras", value: "Las Piedras" },
    { label: "Loiza", value: "Loiza" },
    { label: "Luquillo", value: "Luquillo" },
    { label: "Manatí", value: "Manatí" },
    { label: "Maricao", value: "Maricao" },
    { label: "Maunabo", value: "Maunabo" },
    { label: "Mayagüez", value: "Mayagüez" },
    { label: "Moca", value: "Moca" },
    { label: "Morovis", value: "Morovis" },
    { label: "Naguabo", value: "Naguabo" },
    { label: "Naranjito", value: "Naranjito" },
    { label: "Orocovis", value: "Orocovis" },
    { label: "Patillas", value: "Patillas" },
    { label: "Peñuelas", value: "Peñuelas" },
    { label: "Ponce", value: "Ponce" },
    { label: "Quebradillas", value: "Quebradillas" },
    { label: "Rincón", value: "Rincón" },
    { label: "Rio Grande", value: "Rio Grande" },
    { label: "Sabana grande", value: "Sabana grande" },
    { label: "Salinas", value: "Salinas" },
    { label: "San Germán", value: "San Germán" },
    { label: "San Juan", value: "San Juan" },
    { label: "San Lorenzo", value: "San Lorenzo" },
    { label: "San Sebastián", value: "San Sebastián" },
    { label: "Santa Isabel", value: "Santa Isabel" },
    { label: "Toa Alta", value: "Toa Alta" },
    { label: "Toa Baja", value: "Toa Baja" },
    { label: "Trujillo Alto", value: "Trujillo Alto" },
    { label: "Utuado", value: "Utuado" },
    { label: "Vega Alta", value: "Vega Alta" },
    { label: "Vega Baja", value: "Vega Baja" },
    { label: "Vieques", value: "Vieques" },
    { label: "Villalba", value: "Villalba" },
    { label: "Yabucoa", value: "Yabucoa" },
    { label: "Yauco", value: "Yauco" },
  ];
  return (
    <div className="search-document-page">
      <UpdateHeaderES />
      <div className="search-documents-container">
        <div className="search-document">
          <div className="form-container">
            <h1 className="page-title">Actualizar Documentos</h1>
            <div className="radio-buttons">
              <h2 className="radio-buttons-title">Tipo de Documentos:</h2>
              <RadioButtonsGroupES
                isRadio={isRadio}
                setIsRadio={setIsRadio}
                setGeneralFields={setGeneralFields}
                setAgenciesFields={setAgenciesFields}
                setMunicipalFields={setMunicipalFields}
                setLawFields={setLawFields}
              />
            </div>

            <form
              className="search-documents-form"
              onSubmit={handleSubmit(onUpdateDocuments)}
            >
              <div className="search-bars">
                <h2 className="form-label">Detalle de Documentos:</h2>
                <div
                  className="update-filters"
                  style={{ display: generalFields ? "flex" : "none" }}
                >
                  <div className="form-field">
                    <label>Número de Ley</label>
                    <Controller
                      name="law_number"
                      control={control}
                      defaultValue="law_number"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Input
                          value={value}
                          error={error}
                          id="law_number"
                          type="number"
                          name="law_number"
                          placeholder="Ingrese el Número de Ley"
                          onChange={onChange}
                          className="filter-input"
                        />
                      )}
                    />
                  </div>
                  <div className="form-field">
                    <label>Año</label>
                    <FormSelector
                      value={selectedYear}
                      options={editYears}
                      onChange={handleYearChange}
                    />
                  </div>
                  <div className="form-field">
                    <label>Fecha</label>
                    <Controller
                      name="date"
                      control={control}
                      defaultValue="date"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Input
                          value={value}
                          error={error}
                          id="date"
                          type="text"
                          name="date"
                          placeholder="Introducir Fecha"
                          onChange={onChange}
                          className="filter-input"
                        />
                      )}
                    />
                  </div>
                </div>
                <div
                  className="update-filters"
                  style={{ display: agenciesFields ? "flex" : "none" }}
                >
                  <div className="form-field">
                    <label>Número de Reglamento</label>
                    <Controller
                      name="regulation_number"
                      control={control}
                      defaultValue="regulation_number"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Input
                          value={value}
                          error={error}
                          id="regulation_number"
                          type="number"
                          name="regulation_number"
                          placeholder="Ingrese el Número de Reglamento"
                          onChange={onChange}
                          className="filter-input"
                        />
                      )}
                    />
                  </div>
                  <div className="form-field">
                    <label>Fecha</label>
                    <Controller
                      name="date"
                      control={control}
                      defaultValue="date"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Input
                          value={value}
                          error={error}
                          id="date"
                          type="text"
                          name="date"
                          placeholder="Introducir Fecha"
                          onChange={onChange}
                          className="filter-input"
                        />
                      )}
                    />
                  </div>
                </div>
                <div
                  className="update-filters"
                  style={{ display: agenciesFields ? "flex" : "none" }}
                >
                  <div className="form-field">
                    <label>Agencia</label>
                    <FormSelector
                      value={selectedAgency}
                      options={editAgenciesOptions}
                      onChange={handleAgencyChange}
                    />
                  </div>
                  <div className="form-field">
                    <label>Año</label>
                    <FormSelector
                      value={selectedYear}
                      options={editYears}
                      onChange={handleYearChange}
                    />
                  </div>
                </div>
                <div
                  className="update-filters"
                  style={{ display: municipalFields ? "flex" : "none" }}
                >
                  <div className="form-field">
                    <label>Número de Municipio</label>
                    <Controller
                      name="municipality_number"
                      control={control}
                      defaultValue="municipality_number"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Input
                          value={value}
                          error={error}
                          id="municipality_number"
                          type="number"
                          name="municipality_number"
                          placeholder="Ingrese Número Municipal"
                          onChange={onChange}
                          className="filter-input"
                        />
                      )}
                    />
                  </div>
                  <div className="form-field">
                    <label>Fecha</label>
                    <Controller
                      name="date"
                      control={control}
                      defaultValue="date"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Input
                          value={value}
                          error={error}
                          id="date"
                          type="text"
                          name="date"
                          placeholder="Introducir Fecha"
                          onChange={onChange}
                          className="filter-input"
                        />
                      )}
                    />
                  </div>
                </div>
                <div
                  className="update-filters"
                  style={{ display: municipalFields ? "flex" : "none" }}
                >
                  <div className="form-field">
                    <label>Municipio</label>
                    <FormSelector
                      value={selectedMunicipal}
                      options={editMunicipalOptions}
                      onChange={handleMunicipalChange}
                    />
                  </div>
                  <div className="form-field">
                    <label>Año</label>
                    <FormSelector
                      value={selectedYear}
                      options={editYears}
                      onChange={handleYearChange}
                    />
                  </div>
                </div>
                <div className="search-form-field">
                  <label>Nombre</label>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue="name"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <textarea
                        value={value}
                        onChange={onChange}
                        id="name"
                        name="name"
                        placeholder="Ingrese su Nombre"
                        className="document-input-text-area"
                        required
                      />
                    )}
                  />
                </div>
                <div className="search-form-field">
                  <label>Estatus</label>
                  <FormSelector
                    value={selectedStatus}
                    options={editStatusOptions}
                    onChange={handleStatusChange}
                  />
                </div>
                <div className="file-form-field">
                  <label>Nombre del Archivo</label>
                  <Controller
                    name="file"
                    control={control}
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Input
                        value={value}
                        error={error}
                        id="file"
                        type="text"
                        name="file"
                        placeholder="N/A"
                        onChange={onChange}
                        className="document-input"
                      />
                    )}
                  />
                  <input
                    type="file"
                    accept=".pdf"
                    onChange={handleFileChange}
                    className="document-input"
                  />
                </div>
              </div>
              <div className="checkboxes">
                <div className="checkbox-field">
                  <label>Índice</label>
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={isIndexed}
                    onChange={handleIndexedChange}
                  />
                </div>
                <div className="checkbox-field">
                  <label>Título</label>
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={isTitled}
                    onChange={handleTitleChange}
                  />
                </div>
                <div className="checkbox-field">
                  <label>Terminada</label>
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={isCompleted}
                    onChange={handleCompletedChange}
                  />
                </div>
              </div>
              <div className="actions">
                <button
                  className="document-button"
                  type="button"
                  onClick={handlePreTitle}
                >
                  Título Previo
                </button>
                <button
                  className="document-button"
                  type="button"
                  onClick={handleOpenGetTitleModal}
                >
                  Obtener Título
                </button>
                <button
                  className="document-button"
                  type="button"
                  onClick={handleCancel}
                >
                  Cancelar
                </button>
                <button
                  className="document-button"
                  type="submit"
                  onClick={scrollHandler}
                  disabled={isLoading}
                >
                  {isLoading ? "Actualizando..." : "Actualizar Documentos"}
                </button>
              </div>
              <div className="note">
                <h6>
                  si requieren una ley certificada, por favor pase por el
                  Departamento de Estado cada vez que hay un costo.
                </h6>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        {getTitleModal && (
          <Modal
            title="Get Title"
            content={
              <TextFile
                handleCloseGetTitleModal={handleCloseGetTitleModal}
                textFile={textFile}
              />
            }
            onClose={handleCloseGetTitleModal}
          />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default UpdateDocumentsES;
