import React, { useState, useEffect } from "react";
import DocumentsDetailChart from "./DocumentsDetailChart";
import { getYears } from "../../../../../apis/getYears";
export interface RadioProps {
  isRadio: any;
}

const DocumentsIndexing = ({ isRadio }: RadioProps) => {
  const [years, setYears] = useState<any>([]);

  useEffect(() => {
    if (!isRadio) {
      return;
    }

    let searchType: any;

    if (isRadio === 1) {
      searchType = "Ley";
    } else if (isRadio === 2) {
      searchType = "RC";
    } else if (isRadio === 3) {
      searchType = "Vetos";
    } else if (isRadio === 4) {
      searchType = "Reglamento de Agencias";
    } else {
      searchType = "Reglamento Municipal";
    }

    getYears(searchType)
      .then((userData) => {
        setYears(userData);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [isRadio]);

  return (
    <div className="documents-results">
      <h2>Documents Indexing Detail</h2>
      <DocumentsDetailChart data={years} />
      <div className="results">
        <div className="type">
          <h3>Law:</h3>
          <span>Numbers of documents indexed of Law type.</span>
        </div>
        <div className="type">
          <h3>Joint Resolution:</h3>
          <span>Numbers of documents indexed of Joint Resolution type.</span>
        </div>
        <div className="type">
          <h3>Veto:</h3>
          <span>Numbers of documents indexed of Veto type.</span>
        </div>
        <div className="type">
          <h3>Agencies Regulations:</h3>
          <span>
            Numbers of documents indexed of Agencies Regulations type.
          </span>
        </div>
        <div className="type">
          <h3>Municipal Regulation:</h3>
          <span>
            Numbers of documents indexed of Municipal Regulation type.
          </span>
        </div>
      </div>
    </div>
  );
};

export default DocumentsIndexing;
