import React from "react";
import ReactPaginate from "react-paginate";
import "./PagingNation.css";

interface PagingNationProps {
  onPageChange: (selectedPage: number) => void;
  pageCount: number;
}

const PagingNation: React.FC<PagingNationProps> = ({
  onPageChange,
  pageCount,
}) => {
  const handlePageChange = (selectedItem: { selected: number }) => {
    const selectedPage = selectedItem.selected;
    onPageChange(selectedPage);
  };

  return (
    <ReactPaginate
      previousLabel="Previous"
      nextLabel="Next"
      breakLabel="..."
      breakClassName="break-me"
      pageCount={pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={handlePageChange}
      containerClassName="pagination"
      activeClassName="active"
    />
  );
};

export default PagingNation;
