import { ColumnType } from "../../../../ReuseableComponents/Table/Table.typs";

export const UserTableColumnsESDeleted: (
  onDelete: (id: number) => void,
  onEdit: (id: number) => void,
  onLock: (id: number) => void
) => ColumnType[] = (onDelete, onEdit, onLock) => [
  {
    name: "Nombre de Pila",
    dataKey: "first_name",
    align: "left",
    width: "10%",
  },
  {
    name: "Apellido",
    dataKey: "last_name",
    align: "left",
    width: "10%",
  },
  {
    name: "Correo Electrónico",
    dataKey: "email",
    align: "left",
    width: "10%",
  },
  {
    name: "Fecha de Incorporación",
    dataKey: "date_joined",
    align: "left",
    width: "10%",
    limit: 25,
    render: (cellData, rowData) => {
      return (
        <span>{(rowData?.date_joined?.slice(0, 10))}</span>
      );
    },
  },
];

