import React from "react";
import SearchProperty from "../components/desktop/DE/SearchDocument/SearchProperty";

type Props = {};

function SearchPropertyPage(Props: Props) {

  return (
    <SearchProperty />
  );
}

export default SearchPropertyPage;
