import React from 'react'
import './ReferencedDocuments.scss'

const ReferencedDocuments = () => {
  return (
    <div className="referenced-cards">
        <div className="primary-card-container">
            <h2>Current Document</h2>
            <div className="primary-card">
                <h3>Ley-03 2023</h3>
            </div>
            <div className="primary-card">
                <h3>Ley-27 2023</h3>
            </div>
        </div>
        <div className="primary-card-container">
            <h2>Ammended</h2>
            <div className="secondary-card">
                <h3>Yes</h3>
            </div>
            <div className="secondary-card">
                <h3>No</h3>
            </div>
        </div>
        <div className="primary-card-container">
            <h2>Referenced Document</h2>
            <div className="primary-card">
                <h3>Ley-24 2020</h3>
                <h3>Ley-19 1996</h3>
                <h3>Ley-95 1987</h3>
            </div>
            <div className="primary-card">
                <h3>Ley-27 2023</h3>
            </div>
        </div>
    </div>
  )
}

export default ReferencedDocuments