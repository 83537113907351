import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  baseURL: baseURL
});

// Add a request interceptor to include the Bearer token in headers
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Or use a context/store to get the token
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const exportCSV = async (credentials: Credentials) => {

  let result = await api.post('export_to_csv/', {
    type: credentials.type,
    year: credentials.year,
  });
  return result.data;
};

interface Credentials {
  type: string;
  year: string;
}
