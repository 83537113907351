import React, { useState } from "react";
import "./DESetup.scss";
import NewUserSignup from "./SignUp/Forms";
import Modal from "../../ReuseableComponents/Modal/Modal";
import { MdEdit } from "react-icons/md";
import HeroText from "./Text/HeroText";
import { useNavigate } from "react-router-dom";
import Loader from "../../ReuseableComponents/Loader/Loader";
import HeroTitle from "./Text/HeroTitle";
import HeroContact from "./Text/HeroContact";
export interface StaffProps {
  webTemplate: any;
  loading: any;
  setLoading: any;
  isStaff: any;
}

const HeroSection = ({
  webTemplate,
  loading,
  setLoading,
  isStaff,
}: StaffProps) => {
  const [showModal, setShowModal] = useState(false);
  const [showTextModal, setShowTextModal] = useState(false);
  const [note, setNote] = useState<any>();
  const [showTitleModal, setShowTitleModal] = useState(false);
  const [title, setTitle] = useState<any>();
  const [showContactModal, setShowContactModal] = useState(false);
  const [contact, setContact] = useState<any>();

  let token = localStorage.getItem("token");
  let Note = localStorage.getItem("note");
  let loginLink = "/search-documents-en";

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/search-documents-en");
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenTextModal = () => {
    setShowTextModal(true);
    setNote(webTemplate?.hero_section_body_en);
  };

  const handleCloseTextModal = () => {
    setShowTextModal(false);
  };

  const handleOpenTitleModal = () => {
    setShowTitleModal(true);
    setTitle(webTemplate?.hero_section_title_en);
  };

  const handleCloseTitleModal = () => {
    setShowTitleModal(false);
  };

  const handleOpenContactModal = () => {
    setShowContactModal(true);
    setContact(webTemplate?.hero_section_contact_en);
  };

  const handleCloseContactModal = () => {
    setShowContactModal(false);
  };

  return (
    <div className="hero-page">
      <div className="hero-container">
        <div className="hero-content">
          <div className="top-headings">
            {loading ? (
              <div className="heading-loader">
                <Loader width={330} />
                <h4
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "16px",
                    margin: 0,
                  }}
                >
                  Searching for Title
                </h4>
              </div>
            ) : (
              <h1 className="title">
                {webTemplate?.hero_section_title_en}{" "}
                <MdEdit
                  style={{ display: isStaff ? "inline-block" : "none" }}
                  className="edit-icon"
                  onClick={handleOpenTitleModal}
                />
              </h1>
            )}
            {loading ? (
              <div className="heading-loader">
                <Loader width={330} />
                <h4
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "16px",
                    margin: 0,
                  }}
                >
                  Searching for Contact
                </h4>
              </div>
            ) : (
              <h1 className="email-link">
                {webTemplate?.hero_section_contact_en}{" "}
                <MdEdit
                  style={{ display: isStaff ? "inline-block" : "none" }}
                  className="edit-icon"
                  onClick={handleOpenContactModal}
                />
              </h1>
            )}
          </div>
          <div
            className="hero-button"
            onClick={handleNavigate}
            // style={{ display: token ? "flex" : "none" }}
          >
            Search Documents
          </div>
          {/* <div
            className="hero-button"
            onClick={handleOpenModal}
            style={{ display: token ? "none" : "flex" }}
          >
            Search Documents
          </div> */}
          <div className="note">
            {loading ? (
              <>
                <Loader width={330} />
                <h4
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "16px",
                    margin: 0,
                  }}
                >
                  Searching for Note
                </h4>
              </>
            ) : (
              <h6>
                {webTemplate?.hero_section_body_en}{" "}
                <MdEdit
                  style={{ display: isStaff ? "inline-block" : "none" }}
                  className="edit-icon"
                  onClick={handleOpenTextModal}
                />
              </h6>
            )}
          </div>
          <div className="col-md-3">
            {showModal && (
              <Modal
                title="Puerto Rican Laws"
                content={
                  <NewUserSignup
                    handleCloseModal={handleCloseModal}
                    loginLink={loginLink}
                  />
                }
                onClose={handleCloseModal}
              />
            )}
          </div>
          <div className="col-md-3">
            {showTextModal && (
              <Modal
                title="Update Note"
                content={
                  <HeroText
                    note={note}
                    setNote={setNote}
                    handleCloseTextModal={handleCloseTextModal}
                    loading={loading}
                    setLoading={setLoading}
                  />
                }
                onClose={handleCloseTextModal}
              />
            )}
          </div>
          <div className="col-md-3">
            {showTitleModal && (
              <Modal
                title="Update Title"
                content={
                  <HeroTitle
                    title={title}
                    setTitle={setTitle}
                    handleCloseTitleModal={handleCloseTitleModal}
                    loading={loading}
                    setLoading={setLoading}
                  />
                }
                onClose={handleCloseTitleModal}
              />
            )}
          </div>
          <div className="col-md-3">
            {showContactModal && (
              <Modal
                title="Update Contact"
                content={
                  <HeroContact
                    contact={contact}
                    setContact={setContact}
                    handleCloseContactModal={handleCloseContactModal}
                    loading={loading}
                    setLoading={setLoading}
                  />
                }
                onClose={handleCloseContactModal}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
