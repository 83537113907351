import "./AdminHeader.scss";
import { Link } from "react-router-dom";
import logoo from "../../../../../assests/images/newLogo.png";
import small from "../../../../../assests/images/small.png";
import React, { useState, useEffect } from "react";
import Modal from "../../../../ReuseableComponents/Modal/Modal";
import NewUserSignup from "../../SignUp/Forms";
import { AiOutlineDoubleLeft } from "react-icons/ai";
import AdminSettingSetup from "../AdminSettingSetup";
import AIAnalysis from "../AIAnalysis";
import Loader from "../../../../ReuseableComponents/Loader/Loader";
import BackupRecords from "../BackupRecords/BackupRecords";
import UserManagement from "../UserManagement/UserManagement";
import Usage from "../Usage/Usage";
export interface StaffProps {
  staff: any;
  insightDetails: any;
}

const AdminHeader = ({ staff, insightDetails }: StaffProps) => {
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  let token = localStorage.getItem("token");
  let loginLink = "/admin-setting-en";

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, []);

  const componentList = [
    <AdminSettingSetup />,
    <AIAnalysis insightDetails={insightDetails} />,
    <BackupRecords />,
    <UserManagement />,
    <Usage />
  ];

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <div className="admin-header">
      <div className="small-header">
        <div className="header-left">
          <div className="small">
            <img src={logoo} alt="small" />
          </div>
          <p className="small-haeder-text">
            Official portal of the Government of Puerto Rico
          </p>
        </div>
        <div className="header-right">
          <Link className="navbar-link" to="/admin-setting">
            <h5 className="small-header-text-right">ES</h5>
          </Link>
          <div className="small-header-divider"></div>
          <Link className="navbar-link" to="/admin-setting-en">
            <h5 className="small-header-text-right">EN</h5>
          </Link>
          {token ? (
            <button className="log-out">
              <Link
                className="logout-link"
                to="/admin-setting-en"
                onClick={handleLogout}
              >
                Log out
              </Link>
            </button>
          ) : (
            <button className="log-out" onClick={handleOpenModal}>
              <Link className="logout-link" to="/admin-setting-en">
                Log in
              </Link>
            </button>
          )}
        </div>
      </div>
      {staff ? (
        <>
          {/* <div className="logoo">
            <Link to="/search-documents-EN" className="back">
              {" "}
              <AiOutlineDoubleLeft className="back-icon" /> Back
            </Link>
            <Link className="logout-link" to="/admin-setting-en">
              <img src={logoo} alt="logo" />
            </Link>
          </div> */}
            <div className="new-logo">
          <Link to="/search-documents-EN" className="back">
              {" "}
              <AiOutlineDoubleLeft className="back-icon" /> Back
            </Link>
        <div className="new-logo-container">
          <div className="logo-text">
            <p className="logo-heading">DEPARTMENT OF</p>
            <h3 className="logo-title">STATE</h3>
          </div>
          <div className="logo-divider"></div>
          <img className="new-logo-img" src={logoo} alt="logo" />
        </div>
      </div>
          <div className="documents-analysis">
            <button
              className={`documents-type-button ${page === 0 ? "active" : ""}`}
              onClick={() => setPage(0)}
            >
              Documents Analysis
            </button>
            <button
              className={`documents-type-button ${page === 1 ? "active" : ""}`}
              onClick={() => setPage(1)}
            >
              AI Analysis
            </button>
            <button
              className={`documents-type-button ${page === 2 ? "active" : ""}`}
              onClick={() => setPage(2)}
            >
              Database Logs
            </button>
            <button
              className={`documents-type-button ${page === 3 ? "active" : ""}`}
              onClick={() => setPage(3)}
            >
              Users
            </button>
            <button
              className={`documents-type-button ${page === 4 ? "active" : ""}`}
              onClick={() => setPage(4)}
            >
              Usage
            </button>
          </div>
          <div className="Documents-detail-container">
            {componentList[page]}
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "2rem 0 0 0",
          }}
        >
          {loading ? (
            <Loader width={300} />
          ) : (
            <>
              <h1 style={{ textAlign: "center", margin: "0" }}>Please Login</h1>
              <h3 style={{ textAlign: "center", margin: "5px 0" }}>
                Only Staff Admin can access this page.
              </h3>
            </>
          )}
        </div>
      )}
      <div className="col-md-3">
        {showModal && (
          <Modal
            title="Puerto Rican Laws"
            content={
              <NewUserSignup
                handleCloseModal={handleCloseModal}
                loginLink={loginLink}
              />
            }
            onClose={handleCloseModal}
          />
        )}
      </div>
    </div>
  );
};

export default AdminHeader;
