import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './BackupRecords.scss';

export interface DataProps {
  loggingData: any;
}

const BackupRecordsTable: React.FC<DataProps> = ({ loggingData }) => {
  return (
    <div className="backup-records-table">
      <TableContainer component={Paper}>
        <Table sx={{ width: '100%' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className='title'>User</TableCell>
              <TableCell className='title'>Date</TableCell>
              <TableCell className='title'>Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loggingData.map((row: any) => (
              <TableRow
                key={row.id} // Use a unique identifier for key
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.user}
                </TableCell>
                <TableCell>{row.date}</TableCell>
                <TableCell>{new Date(row.time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}</TableCell> {/* Format time with AM/PM */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default BackupRecordsTable;
