import React, { useState, useEffect } from "react";
import { getYears } from "../../../../../apis/getYears";
import DocumentsDetailChartES from "./DocumentsDetailChartES";
export interface RadioProps {
  isRadio: any;
}

const DownloadedDocumentsES = ({ isRadio }: RadioProps) => {
  const [years, setYears] = useState<any>([]);

  useEffect(() => {
    if (!isRadio) {
      return;
    }

    let searchType: any;

    if (isRadio === 1) {
      searchType = "Ley";
    } else if (isRadio === 2) {
      searchType = "RC";
    } else if (isRadio === 3) {
      searchType = "Vetos";
    } else if (isRadio === 4) {
      searchType = "Reglamento de Agencias";
    } else {
      searchType = "Reglamento Municipal";
    }

    getYears(searchType)
      .then((userData) => {
        setYears(userData);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [isRadio]);

  return (
    <div className="documents-results">
      <h2>Detalle de Documentos Descargados</h2>
      <DocumentsDetailChartES data={years} />
      <div className="results">
        <div className="type">
          <h3>Ley:</h3>
          <span>Número de documentos descargados de tipo Ley.</span>
        </div>
        <div className="type">
          <h3>Resolución Conjunta:</h3>
          <span>
            Números de documentos descargados de tipo Resolución Conjunta.
          </span>
        </div>
        <div className="type">
          <h3>Veto:</h3>
          <span>Números de documentos descargados de tipo Veto.</span>
        </div>
        <div className="type">
          <h3>Reglamento de Agencias:</h3>
          <span>
            Números de documentos descargados de tipo Reglamento de Agencias.
          </span>
        </div>
        <div className="type">
          <h3>Reglamento Municipal:</h3>
          <span>
            Números de documentos descargados de tipo Reglamento Municipal.
          </span>
        </div>
      </div>
    </div>
  );
};

export default DownloadedDocumentsES;
