import { DeleteIcon, EditIcon 
} from "../../../../../assests/images/svgComponents";
import { ColumnType } from "../../../../ReuseableComponents/Table/Table.typs";

export const GroupTableColumnsES: (
  onDelete: (id: number) => void,
  onEdit: (id: number) => void
) => ColumnType[] = (onDelete, onEdit) => [
  {
    name: "Nombre Completo",
    dataKey: "name",
    align: "left",
    width: "5%",
  },
  {
    name: "Nivel",
    dataKey: "level",
    align: "left",
    width: "5%",
  },
  {
    name: "Comportamiento",
    dataKey: "actions",
    align: "right",
    width: "5%",
    limit: 25,
    render: (cellData, rowData) => {
      return (
        <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', gap: '5px', paddingRight: '10px'}}>
          <span
            className="status"
            onClick={() => {
              onEdit(rowData.id);
            }}
          >
            <EditIcon />
          </span>
          <span
            className="status"
            onClick={() => {
              onDelete(rowData.id);
            }}
          >
            <DeleteIcon />
          </span>
        </div>
      );
    },
  },
];
