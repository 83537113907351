import "../AdminSettingSetup.scss";
export interface RadioProps {
  isRadio: any;
  setIsRadio: any;
}
const DocumentTypes = ({ isRadio, setIsRadio }: RadioProps) => {
  const handleChange = (e: any) => {
    setIsRadio(+e.currentTarget.value);
  };

  return (
    <div className="document-radio-buttons">
      <div className="radio-button">
        <input
          type="radio"
          id="radio1"
          value="1"
          onChange={handleChange}
          checked={isRadio === 1}
        />
        <label>Law</label>
      </div>
      <div className="radio-button">
        <input
          type="radio"
          id="radio2"
          value="2"
          onChange={handleChange}
          checked={isRadio === 2}
        />
        <label>Joint Resolution</label>
      </div>
      <div className="radio-button">
        <input
          type="radio"
          id="radio3"
          value="3"
          onChange={handleChange}
          checked={isRadio === 3}
        />
        <label>Veto</label>
      </div>
      <div className="radio-button">
        <input
          type="radio"
          id="radio4"
          value="4"
          onChange={handleChange}
          checked={isRadio === 4}
        />
        <label>Agencies Regulations</label>
      </div>
      <div className="radio-button">
        <input
          type="radio"
          id="radio5"
          value="5"
          onChange={handleChange}
          checked={isRadio === 5}
        />
        <label>Municipal Regulation</label>
      </div>
    </div>
  );
};

export default DocumentTypes;
