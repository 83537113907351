import React from 'react';
import { getHyperLinkDocument } from '../../../../apis/getHyperLinkDocument';
import { toast } from 'react-toastify';

interface Props {
  name?: string;
  type?: any;
  law_number?: any;
  year?: any;
  hyperLinkDocument: any;
  setHyperLinkDocument: any;
  setLoadingButton: any;
}

const HyperlinkParagraph: React.FC<Props> = ({ name, hyperLinkDocument, setHyperLinkDocument, setLoadingButton }) => {

  function handleLinkClick(matchedText: string) {
    setLoadingButton(true);
    const hyperLinkYear = matchedText.slice(-4);
    const hyperLinkNumber = matchedText.slice(4, -5);
    const hyperLinkData = {
      hyperLinkNumber: hyperLinkNumber,
      hyperLinkYear: hyperLinkYear,
    };

    getHyperLinkDocument(hyperLinkData)
      .then((resp) => {
        setHyperLinkDocument(resp);
        setLoadingButton(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Document not found!");
        setLoadingButton(false);
      });
  }
  
  function handleLinkClickTwo(matchedText: string) {
    setLoadingButton(true);
    const hyperLinkYear = matchedText.slice(-4);
    const matchedDigits = matchedText.slice(9, 12).match(/\d+/);
    const hyperLinkNumber: number = matchedDigits ? parseInt(matchedDigits[0], 10) : NaN;
    const hyperLinkData = {
      hyperLinkNumber: hyperLinkNumber,
      hyperLinkYear: hyperLinkYear,
    };

    getHyperLinkDocument(hyperLinkData)
      .then((resp) => {
        setHyperLinkDocument(resp);
        setLoadingButton(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Document not found!");
        setLoadingButton(false);
      });
  }

  if (!name) {
    return null;
  }

  const customPattern = /Ley \d{1,3}-\d{4}/g;
  const customPatternTwo = /enmendar/g;
  const customPatternThree = /enmendada/g;
  const customPatternFour = /Ley Núm. \d{1,3} de \d{1,3} de [^\s]* de \d{4}/g;

  const patterns = new RegExp(`(${customPattern.source}|${customPatternTwo.source}|${customPatternThree.source}|${customPatternFour.source})`, 'g');
  const parts = name.split(patterns);
  const content: JSX.Element[] = [];

  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];

    if (customPattern.test(part)) {
      content.push(
        <button
          key={i}
          style={{ fontWeight: 'bold', fontSize: '17px', color: '#1869b3', padding: 0, cursor: 'pointer', background: 'none', outline: 'none', border: 'none', textDecoration: 'underline' }}
          onClick={() => handleLinkClick(part)}
        >
          {part}
        </button>
      );
    } else if (customPatternTwo.test(part)) {
      content.push(
        <span
          key={i}
          style={{ color: 'orange'}}
          
        >
          {part}
        </span>
      );
    } else if (customPatternThree.test(part)) {
      content.push(
        <span
          key={i}
          style={{ color: 'brown' }}
        >
          {part}
        </span>
      );
    } else if (customPatternFour.test(part)) {
      content.push(
        <button
          key={i}
          style={{ fontWeight: 'bold', fontSize: '17px', color: '#1869b3', padding: 0, cursor: 'pointer', background: 'none', outline: 'none', border: 'none', textDecoration: 'underline' }}
          onClick={() => handleLinkClickTwo(part)}
        >
          {part}
        </button>
      );
    } else {
      content.push(<span key={i}>{part}</span>);
    }
  }

  return <div>{content}</div>;
};

export default HyperlinkParagraph;
