import { useEffect, useState } from "react";
import "./UserManagement.scss"
import Modal from "../../../../ReuseableComponents/Modal/Modal";
import CreateNewUserES from "./CreateNewUserES";
import UserTableES from "./UserTableES";
import UserDesktop from "../user/UserDesktop";
import UserDesktopES from "../user/UserDesktopES";
import GroupSetupES from "../Groups/GroupsSetupES";
import { filterAdmins } from "../../../../../apis/filterAdmins";
import { getFilterUsers } from "../../../../../apis/getFilterUsers";
import DeletedUserDesktopES from "../user/DeletedUserDesktopES";

const UserManagementES = () => {
  const [createUserForm, setCreateUserForm] = useState(false);
  const [usertype, setUsertype] = useState(false);
  const [filterUsers, setFilterUsers] = useState();
  const [filterUsersLoading, setFilterUsersLoading] = useState(false);
  const handleOpenCreateUserForm = () => {
    setCreateUserForm(true);
  };

  const handleCloseCreateUserForm = () => {
    setCreateUserForm(false);
  };
  
  const handleFilterAdmins = () => {
    setUsertype(true)
    setFilterUsersLoading(true)
    filterAdmins()
    .then((resp) => {
      setFilterUsers(resp)
      setFilterUsersLoading(false)
    })
    .catch((err) => {
      console.log(err)
    });
  };

  const handleFilterUsers = () => {
    setUsertype(false)
    setFilterUsersLoading(true)
    getFilterUsers()
    .then((resp) => {
      setFilterUsers(resp)
      setFilterUsersLoading(false)
    })
    .catch((err: any) => {
      console.log(err)
    });
  };

  useEffect(() => {
    handleFilterUsers()
  
    return () => {
      handleFilterUsers()
    }
  }, [])

  return (
    <div className="user-management-container">
      <div className="topbar">
        <h1 className="title">Gestión de Usuarios</h1>
        <div className="filter-buttons">
          <button 
            className="filter-button" 
            style={{color: usertype ? '#fff' : 'green'}}
            onClick={handleFilterUsers}
          >
            Usuarios
          </button>
          <button 
            className="filter-button" 
            style={{color: usertype ? 'green' : '#fff'}}
            onClick={handleFilterAdmins}
          >
            Administradoras
          </button>
        </div>
      </div>
      <div className="topbar">
        <h2 className="title-heading">{usertype ? 'Administradoras' : 'Usuarios'}</h2>
        <button className="add-user-button" onClick={handleOpenCreateUserForm}>+Nueva</button>
      </div>
      <UserDesktopES filterUsers={filterUsers} filterUsersLoading={filterUsersLoading} usertype={usertype}/>
      <h1 className="title">Usuarios Eliminadas</h1>
      <DeletedUserDesktopES filterUsers={filterUsers} filterUsersLoading={filterUsersLoading} usertype={usertype}/>
      <h1 className="title">Manejo de Grupo</h1>
      <GroupSetupES />
      <div className="col-md-3">
        {createUserForm && (
          <Modal
            title="Crear nueva Usuaria"
            content={<CreateNewUserES handleCloseCreateUserForm={handleCloseCreateUserForm}/>}
            onClose={handleCloseCreateUserForm}
          />
        )}
      </div>
    </div>
  )
}

export default UserManagementES