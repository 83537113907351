import { useEffect, useState } from "react";
import { getSignUpUserDetail } from "../apis/getSignupUserDetail";
import { getInsightDetails } from "../apis/getInsightDetails";
import AdminHeaderES from "../components/desktop/DE/AdminSetting/AdminHeader/AdminHeaderES";

type Props = {};

function AdminSettingES(Props: Props) {
  let userID = localStorage.getItem("user_id");
  const [staff, setStaff] = useState();
  const [insightDetails, setInsightDetails] = useState();

  useEffect(() => {
    getSignUpUserDetail(userID)
      .then((userData) => {
        setStaff(userData?.is_staff);
      })
      .catch((err) => {
        console.log(err);
      });
    getInsightDetails()
      .then((insightResponse) => {
        setInsightDetails(insightResponse?.document_insights);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [staff]);

  return <AdminHeaderES staff={staff} insightDetails={insightDetails} />;
}

export default AdminSettingES;
