import React from "react";
import { deleteDocument } from "../../../../../apis/deleteDocument";
import "./DeleteDocuments.scss";
import { toast } from "react-toastify";
import { hideDocument } from "../../../../../apis/HideDocument";

export interface TextProps {
  handleCloseUnHideModal: any;
  docID: any;
  setIsHideLoading: any;
}

const UnHideDocumentES: React.FC<TextProps> = ({
  handleCloseUnHideModal,
  docID,
  setIsHideLoading
}) => {
  const onHideDocument = () => {
    setIsHideLoading(true)
    
    const update = {
      id: docID,
      is_hidden: false,
    };
    hideDocument(update)
      .then((resp) => {
        setIsHideLoading(false)
        handleCloseUnHideModal();
        toast.success("Mostrar Documento Correctamente");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error al Ocultar Documentos. Inténtalo de Nuevo.");
        handleCloseUnHideModal();
      });
  };
  return (
    <div className="delete-document-container">
      <h3>¿Estás Segura de Mostrar Este Documento?</h3>
      <div className="delete-buttons">
        <button onClick={onHideDocument}>Sí</button>
        <button onClick={handleCloseUnHideModal}>Cancelar</button>
      </div>
    </div>
  );
};

export default UnHideDocumentES;
