import React, { useState } from "react";
import "./UploadCSV.scss";
import { toast } from "react-toastify";
import { csvFileUpload } from "../../../../../apis/csvFileUpload";

export interface TextProps {
  handleCloseFileModal: () => void;
}

const UploadCSV: React.FC<TextProps> = ({ handleCloseFileModal }) => {
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [res, setRes] = useState();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setSelectedFile(file);
  };

  const onUploadCSV = () => {
    const update = {
      csv_record: selectedFile,
    };
    setLoading(true);
    csvFileUpload(update)
      .then((response) => {
        toast.success("CSV File Uploaded Successfully");
        setLoading(false);
        setRes(response?.message);
        setTimeout(function () {
          handleCloseFileModal();
        }, 5000);
      })
      .catch((error) => {
        toast.error("CSV File Uploading Error. Please Try Again.");
        setLoading(false);
      });
  };

  return (
    <div className="edit-text">
      <input
        type="file"
        accept=".csv"
        onChange={handleFileChange}
        className="edit-input"
      />
      <button className="edit-button" onClick={onUploadCSV}>
        {loading ? "Uploading..." : "Upload"}
      </button>
      {res ? res : ""}
    </div>
  );
};

export default UploadCSV;
