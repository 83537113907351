import DEESSetup from '../components/desktop/DE/DEESSetup';

type Props = {};

function DEES(Props: Props) {

  return <DEESSetup/>;
}

export default DEES; 
