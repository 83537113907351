import React, { useEffect, useState } from "react";
import SearchDocuments from "../components/desktop/DE/SearchDocument/SearchDocuments";
import { postAnalysis } from "../apis/postAnalysis";
import DownloadList from "../components/ReuseableComponents/DownloadList/DownloadList";
import PageLoader from "../components/ReuseableComponents/Loader/PageLoader";

type Props = {};

function SearchDocumentsDE(Props: Props) {
  const [error, setError] = useState("");
  const [cursor, setCursor] = useState("");
  const [demandLoading, setDemandLoading] = useState(false);
  const [disabledButtons, setDisabledButtons] = useState<any>([]);

  const handleButtonClick = (items: any) => {
    if (disabledButtons.length < 3) {
      setDisabledButtons(
        (prevDisabledButtons: { doc_id: string; documentName: string }[]) => [
          ...prevDisabledButtons,
          { doc_id: items.doc_id, documentName: items.name, Created: items.created, CreatedBy: items.created_by, Modified: items.modified, ModifiedBy: items.modified_by, Pages: items.pages, Documento: items.type, NumLey: items.law_number, Year: items.year, Caja: "1830", Titulo: "", Tipo: "Original", Estatus: items.status, Fecha: items.date, NombreAgencia: items.agency, Municipio: items.municipality },
        ]
      );
    } else {
      setError("Rango Excedido");
    }
  };

  useEffect(() => {
    if (disabledButtons.length < 3) {
      setError("");
      setCursor("");
    } else {
      setCursor("not-allowed");
    }
  }, [disabledButtons]);

  const handleRemove = (itemToRemove: any) => {
    const updatedButtons = disabledButtons.filter(
      (item: any) => item !== itemToRemove
    );
    setDisabledButtons(updatedButtons);
  };

  useEffect(() => {
    const searchPageHit = {
      home: false,
      search: true,
    };
    postAnalysis(searchPageHit)
      .then((userData) => {
        console.log(userData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <SearchDocuments
        handleButtonClick={handleButtonClick}
        disabledButtons={disabledButtons}
        cursor={cursor}
      />
      <DownloadList
        disabledButtons={disabledButtons}
        setDisabledButtons={setDisabledButtons}
        handleRemove={handleRemove}
        error={error}
        demandLoading={demandLoading}
        setDemandLoading={setDemandLoading}
      />
      {demandLoading ? <PageLoader /> : ""}
    </>
  );
}

export default SearchDocumentsDE;
