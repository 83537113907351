import React from "react";
import { deleteDocument } from "../../../../../apis/deleteDocument";
import "./DeleteDocuments.scss";
import { toast } from "react-toastify";

export interface TextProps {
  handleCloseDeleteModal: any;
  deleteID: any;
  deleteLoading: any;
  setDeleteLoading: any;
}

const DeleteSearchDocument: React.FC<TextProps> = ({
  handleCloseDeleteModal,
  deleteID,
  deleteLoading,
  setDeleteLoading,
}) => {
  const onDeleteDocument = () => {
    setDeleteLoading(true);
    deleteDocument(deleteID)
      .then((resp) => {
        toast.success("Document Deleted Successfully");
        setDeleteLoading(false);
        handleCloseDeleteModal();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error deleting documents. Please try again.");
        handleCloseDeleteModal();
      });
  };
  return (
    <div className="delete-document-container">
      <h3>Are you sure to delete ?</h3>
      <div className="delete-buttons">
        <button onClick={onDeleteDocument}>Yes</button>
        <button onClick={handleCloseDeleteModal}>Cancel</button>
      </div>
    </div>
  );
};

export default DeleteSearchDocument;
